// Custom Variables //
$primaryFont: 'Apercu Pro';
$arprimaryFont: '29LT Ravi';

// Set Colors
$black: #000000;
$white: #ffffff;
$iPrimary: #7b8a95;
$iSecondary: #00733a;
$iSecondaryDark: #005129;

$iSecondary-50: #7fb99c;

$iGray: #8f8f8f;
$iBlack: #3c3f40;

$iRed: #f16669;
$iRedDark: #f8363d;

$black-shade1:#3a454a;
$black-shade2:#353535;
$black-shade3:#26353d;
$black-shade2-10: rgba(53,53,53, 0.1);

$iBlue: #4155a5;
$iBlueDark: #35437c;

$iPink: #f1666b;
$iPinkDark: #de6d66;

$iPurple: #d4d1e8;

$iLight-green: #c0d7c4;

$iredSpan: #e50b30;
$linkSecondary: $iSecondary;
$linkGray: #697981;
$lightGray: #f5f6f6;
$lightGray1: #dee0e0;
$badgeGray: #eeeeee;

$lineshade: rgba(77, 80, 87, .2);
$boxShadow: 4px 4px 20px rgba(244,244,244, 1);
$buttonShadow-green: 10px 10px 14px rgba(0,115,58, .2);
$buttonShadow-blue: 10px 10px 14px rgba(66,85,165, .2);
$buttonShadow-pink: 10px 10px 14px rgba(241, 102, 105, .2);
$buttonShadow-red: 10px 10px 14px rgba(241, 102, 105, .2);

$headingColor: #222222;
$iconColor: $headingColor;
$lightGray2: #cad3d8;
$lightGray3:#bdc4ca;
$dateColor: $iSecondary;
$borderLine: #cad3d8;
$borderLeftLine:#586368;
$inputColor:#4f5b62;
$gradientShade:#232b35;
$resetColor:#818f99;
$placeholder: $headingColor;
$line: #cdd0d1;
$lightGreen: #c0d7c3;

// Media Sizes
$vpmobileRev: "(min-width:768px)";
$vpMXdesktopMin: "(min-width: 1570px)";
$vpMXdesktopMax: "(max-width: 1569px)";
$vpdesktopMin: "(min-width:2100px)";

$vpMddesktop: "(max-width:1600px)"; // Extended Desktop size
$vpdesktop: "(max-width:1440px)"; // Mac size
$vpSdesktop: "(max-width:1359px)"; // Small Desktop size
$vptabletLMin: "(min-width:1200px)";
$vptabletL: "(max-width:1199px)";
$vptabletPMin: "(min-width:992px)";
$vptabletP: "(max-width:991px)";
$vpmobileMin: "(min-width:768px)";
$vpmobile: "(max-width:767px)";
$vpsmmobile: "(max-width:575px)";
$vpsmobile: "(max-width: 425px)";
$vpxsmobile: "(max-width:359px)";