.home-banner .banner-content .content {
    h2 {
        overflow: hidden;
        white-space: normal;
        max-width: 0;
        transition: max-width 0.7s;
        overflow: hidden;
        opacity: 0;
        /*rtl:raw:
        margin-bottom: 0;
        padding-bottom: 15px;
        */
        span:not(.sprayed) {
            transform: translateY(100%);
            transition: all 0.6s;
            transition-delay: 0.4s;
            opacity: 0;
            position: relative;
            width: 100%;
            /*rtl:raw: 
            padding-bottom: 15px;
            padding-left: 5px;
            */
            &::after {
                content: '';
                position: absolute;
                background: $black;
                height: 100%;
                top: 0;
                z-index: -1;
                animation-name: tempspray;
                animation-timing-function: linear;
                animation-duration: 0.8s;
                animation-delay: 1s;
                animation-iteration-count: 1;
                animation-play-state: paused;
            }
        }
        + p {
            transform: translateY(100%);
            transition: all 0.6s;
            transition-delay: 0.9s;
            opacity: 0;
        }
    }
}
.page-loaded {
    .home-banner .banner-content .content {
        h2 {
            max-width: 100%;
            opacity: 1;
            span:not(.sprayed) {
                transform: translateY(0);
                opacity: 1;
                &::after {
                    animation-play-state: running;
                }
            }
            + p {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

.videopopup-style .videopopup-wrapper .video-text,
.cover-slider .cover-item .cover-content {
    h2 {
        overflow: hidden;
        white-space: nowrap;
        max-width: 0;
        transition: max-width 0.7s;
        overflow: hidden;
        opacity: 0;
        /*rtl:raw:
        margin-bottom: 0;
        padding-bottom: 15px;
        */
        span:not(.sprayed) {
            transform: translateY(100%);
            transition: all 0.6s;
            transition-delay: 0.4s;
            opacity: 0;
            position: relative;
            /*rtl:raw: 
            padding-bottom: 15px;
            padding-left: 5px;
            */
            &::after {
                content: '';
                position: absolute;
                background: $black;
                height: 100%;
                top: 0;
                z-index: -1;
                animation-name: tempspray;
                animation-timing-function: linear;
                animation-duration: 0.8s;
                animation-delay: 1s;
                animation-iteration-count: 1;
                animation-play-state: paused;
            }
        }
        + .u-desktop-only p,
        + .u-desktop-only a,
        + a {
            transform: translateY(100%);
            transition: all 0.6s;
            transition-delay: 0.9s;
            opacity: 0;
        }
    }
    &.aos-animate {
        h2 {
            max-width: 100%;
            opacity: 1;
            span:not(.sprayed) {
                transform: translateY(0);
                opacity: 1;
                &::after {
                    animation-play-state: running;
                }
            }
            + .u-desktop-only p,
            + .u-desktop-only a,
            + a {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}


@keyframes tempspray {
    0% {left: 0; right: 100%}
    48% {left: 0; right: 0}
    52% {left: 0; right: 0}
    100% {left: 100%; right: 0}
}
@keyframes iconpop {
    0%   {
        transform: translateY(10px) scale(0);
        opacity: 0;
    }
    50%  {
        transform: scale(1.5);
    }
    100% {
        transform: translateY(0) scale(0);
        opacity: 1;
    }
}

@keyframes elastic {
    0% {
        border-radius: 50%;
    }
    45% {
        border-radius: 0;
    }
    65% {
        border-top-right-radius: 40px 50%;
        border-bottom-right-radius: 40px 50%;
    }
    80% {
        border-radius: 0;
    }
    90% {
        border-top-right-radius: 20px 50%;
        border-bottom-right-radius: 20px 50%;
    }
    100% {
        border-radius: 0;
    }
}
