.sec-about-us{
    .about-content{
        @media #{$vptabletL} {
            padding-right: 0;
        }

        h1{
            margin-top: 25px;

            @media #{$vptabletL} {
                margin-top: 0;
            }
        }

        p {
            margin-bottom: 15px;
        }

        .last {
            margin-bottom: 0;
        }

        &.sty1 {
            padding: 25px 0 0;

            @media #{$vptabletP} {
                .btn {
                    margin: 0;
               }
            }
        }

        @media #{$vptabletPMin} {
            a {
                margin: 0;
            }
        }
    }
}

.clip-mask {
    @media #{$vptabletP} {
        margin: 0 0 40px;
    }
}

.sec-vision {
    overflow: hidden;

    .vision-wrapper {
        display: flex;

        @media #{$vpmobile} {
            .slick-list{
                overflow: initial;
                .slick-track{
                    .slick-slide{
                        padding: 0 4px;
                    }
                }
            }

            &.slick-initialized {
                display: block;
                padding-right: 0;
                margin: 0 -4px;
            }
        }

        @media #{$vpxsmobile} {
            margin-left: -15px;
            margin-right: -15px;
        }

        @media #{$vptabletL} {
            flex-wrap: wrap;
        }

        .vision-box{
            flex: 0 0 33.33%;
            max-width: 33.33%;

            @media #{$vptabletL} {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;

                .vision-img {
                    flex: 0 0 50%;
                    max-width: 50%;
                    @media #{$vpmobile} {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                .vision-content{
                    flex: 0 0 50%;
                    max-width: 50%;
                    @media #{$vpmobile} {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            @media #{$vpmobile} {
                position: relative;
            }

            .vision-img {
                position: relative;
                padding-top: 75%;

                @media #{$vptabletL} {
                    padding-top: 50%;
                }

                @media #{$vpmobile} {
                    padding-top: 170%;
                    z-index: 1;
                }

                img,
                picture{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                }
            }

            .vision-content{
                position: relative;
                padding: 75% 0 0 0;
                overflow: hidden;

                @media #{$vptabletL} {
                    padding: 50% 0 0 0;
                }

                @media #{$vpmobile} {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 0;
                    z-index: 2;
                }

                .content-box {
                    position: absolute;
                    width: 75%;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    max-height: 100%;

                    @media #{$vpdesktop} {
                        margin-left: 20px;
                    }

                    @media #{$vptabletL} {
                        margin-left: 0;
                    }

                    @media #{$vpmobile} {
                        position: relative;
                        top: auto;
                        left: auto;
                        width: auto;
                        height: auto;
                        transform: none;
                        padding: 130px 30px 40px;
                        background-color: $gradientShade;
                        background: linear-gradient(to bottom,  rgba($gradientShade,0) 0%,rgba($gradientShade,1) 50%);
                    }

                    h1 {
                        &:before,
                        &:after {
                            display: none;
                        }
                        @media #{$vpdesktop} {
                            font-size: 40px;
                        }
                        @media #{$vpmobile} {
                            color: $white;
                            font-size: 35px;
                            margin-bottom: 20px;
                        }
                    }

                    p {
                        @media #{$vpmobile} {
                            color: $white;
                        }
                    }
                }
            }

            &:nth-child(2){
                display: flex;
                flex-direction: column-reverse;
                @media #{$vptabletL} {
                    flex-direction: row-reverse;
                }
            }
        }

        .card-shadow {
            &.top-right {
                &:before {
                    @media #{$vptabletL} {
                        transform: translate(-10px,10px);
                    }
                }
            }

            &.bottom-right {
                &:before {
                    @media #{$vptabletL} {
                        transform: translate(10px, -10px);
                    }
                }
            }
            @media #{$vpmobile} {
                &:before {
                    display: none;
                }
            }
        }
    }
}