.thumb-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;

    // @media #{$vpSdesktop} {
    //     margin-left: -30px;
    //     margin-left: -30px;
    // }

    // @media #{$vptabletL} {
    //     margin-left: -20px;
    //     margin-right: -20px;
    // }

    @media #{$vpmobile} {
        // margin-left: -10px;
        // margin-right: -10px;
        margin-bottom: 20px;
    }

    &.nth-even-odd {
        .b-thumb-wrapper {
            &:nth-child(odd) {
                .card-shadow {
                    &:before {
                        transform: translate(-10px, -10px);
                    }
                }
            }
        } 

        // OVERRIDING global nth-odd event 
        .card-shadow {
            &:nth-child(odd) {
                &:before {
                    transform: translate(-10px, 10px);
                }
            }
        }
    }

    &.nth-middle-item {
        .b-thumb-wrapper {
            .card-shadow {
                &:before {
                    transform: translate(-10px, 10px);
                }
            }

            &:nth-child(3n-1) {
                .card-shadow {
                    &:before {
                        transform: translate(-10px, -10px);
                    }
                }
            }
        
            &:nth-child(3n+1) {
                .card-shadow {
                    &:before {
                        transform: translate(10px, -10px);
                    }
                }
            }

            @media #{$vptabletL} {
                &:nth-child(even) {
                    .card-shadow {
                        &:before {
                            transform: translate(-10px, -10px);
                        }
                    }
                }
            
                &:nth-child(odd) {
                    .card-shadow {
                        &:before {
                            transform: translate(-10px, 10px);
                        }
                    }
                }
            }

            @media #{$vpmobile} {
                &:nth-child(odd) {
                    .card-shadow {
                        &:before {
                            transform: translate(-10px, -10px);
                        }
                    }
                }
            
                &:nth-child(even) {
                    .card-shadow {
                        &:before {
                            transform: translate(-10px, 10px);
                        }
                    }
                }
            }
        } 
    }

    &.exhibition--card {
        margin-bottom: 0;

        .b-thumb-content-wrap {
            box-shadow: 5px 5px 25px rgba(172, 185, 197, .25);
            .b-thumb-content {
                padding: 20px;
                max-width: none;
                margin: 0;
                .b-thumb-datetime {
                    margin: 0;
                    .time {
                        padding-right: 20px;
                    }
                }
            }
        }

        .b-thumb-datetime {
            color: $black-shade2;

            .time {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-basis: 25%;
                white-space: nowrap;
            }
            .date {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-grow: 1;
            }
            svg {
                width: 15px;
                height: 15px;
                display: inline-block;
                margin: 0 10px 0 0;
            }
            .badge-wrap {
                flex-basis: 100%;
                margin: 10px 0 0;

                span {
                    margin: 0;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background: $badgeGray;
                    color: $black-shade2;
                    font-weight: normal;
                }
            }
        }

        .b-thumb-action {
            margin: 30px 0 0;
        }

        &.slick-initialized {
            display: block;
            position: relative;

            .b-thumb-wrapper {
                max-width: 100%;
                flex-basis: 100%;
            }

            .slick-arrow {
                z-index: 10;
                position: absolute;
                left: -60px;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                box-shadow: none;
                font-size: 0;
                width: 40px;
                height: 40px;
                display: block;
                line-height: 40px;
                transition: opacity 0.3s ease 0s;
                opacity: 1;

                &:hover {
                    opacity: 0.75;
                }

                svg {
                    margin: auto;
                    display: block;
                    width: 11px;
                    height: 16px;
                }

                &.slick-next {
                    left: auto;
                    right: -60px;
                }

                @media #{$vpmobile} {
                    display: none !important;
                }
            }

            @media screen and (max-width: 1660px) and (min-width: 1570px) {
                .slick-arrow {
                    left: -35px;
                    
                    &.slick-next {
                        left: auto;
                        right: -35px;
                    }
                }

                &:hover {
                    .slick-arrow {
                         opacity: 0.75;
                    }
                }
            }

            @media screen and (max-width: 1260px) and (min-width: 1200px) {
                .slick-arrow {
                    left: -35px;
                    
                    &.slick-next {
                        left: auto;
                        right: -35px;
                    }
                }

                &:hover {
                    .slick-arrow {
                        opacity: 0.75;
                    }
                }
            }

            @media screen and (max-width: 1199px) {
                padding-left: 20px;
                padding-right: 20px;
                margin: 0 -25px;

                .slick-arrow {
                    left: -10px;
                    &.slick-next {
                        left: auto;
                        right: -10px;
                    }
                }
            }

            @media #{$vpmobile} {
                .slick-list {
                    overflow: visible;
                }

                .b-thumb-wrapper {
                    padding: 20px 6px;
                }
            }
        }
    }
    
    &.thumb-card {
        margin-left: -15px;
        margin-right: -15px;

        .b-thumb-wrapper {
            padding: 32px 15px 10px;

            @media #{$vpmobile} {
                padding: 20px 15px;
            }

            .b-thumb-content {
                margin: 30px 0 20px;
                max-width: 100%;
            }

            .b-thumb-action {
                justify-content: flex-start;
            }
        }
    }

    &.gutter-15 {
        margin-left: -15px;
        margin-right: -15px;

        @media #{$vptabletL} {
            margin-left: -10px;
            margin-right: -10px;
        }

        .b-thumb-wrapper {
            padding-left: 15px;
            padding-right: 15px;

            @media #{$vptabletL} {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    
}

.thumb-wrapper {
    &.slick-initialized {
        display: block;
        position: relative;

        .b-thumb-wrapper {
            max-width: 100%;
            flex-basis: 100%;
        }

        .slick-arrow {
            z-index: 10;
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            font-size: 0;
            width: 40px;
            height: 40px;
            display: block;
            line-height: 40px;
            transition: opacity 0.3s ease 0s;
            opacity: 1;

            &:hover {
                opacity: 0.75;
            }

            svg {
                margin: auto;
                display: block;
                width: 11px;
                height: 16px;
            }

            &.slick-next {
                left: auto;
                right: -40px;
            }

            @media #{$vpmobile} {
                display: none !important;
            }
        }

        @media screen and (max-width: 1660px) and (min-width: 1570px) {
            .slick-arrow {
                left: -35px;
                
                &.slick-next {
                    left: auto;
                    right: -35px;
                }
            }

            &:hover {
                .slick-arrow {
                     opacity: 0.75;
                }
            }
        }

        @media screen and (max-width: 1260px) and (min-width: 1200px) {
            .slick-arrow {
                left: -35px;
                
                &.slick-next {
                    left: auto;
                    right: -35px;
                }
            }

            &:hover {
                .slick-arrow {
                    opacity: 0.75;
                }
            }
        }

        @media screen and (max-width: 1199px) {
            padding-left: 20px;
            padding-right: 20px;

            .slick-arrow {
                left: -15px;

                &.slick-next {
                    left: auto;
                    right: -15px;
                }
            }
        }

        @media #{$vpmobile} {
            .slick-list {
                overflow: visible;
            }
        }
    }
}

.sec-permenent-exhibition {
    @media #{$vpmobile} {
        overflow: hidden;
        .align-center,
        .text-center {
            text-align: left;
            align-items: flex-start;

            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                text-align: left;
                align-items: flex-start;
            }
        }
        .thumb-wrapper.exhibition--card.slick-initialized {
            margin-left: -5px;
            margin-right: -5px;

            .b-thumb-wrapper {
                padding-left: 5px;
                padding-right: 5px;
            }

            padding: 0;
            .slick-list {
                overflow: visible;
            }

            .slick-arrow {
                display: none !important;
            }
        }
    }
}

.b-thumb {
    &-img {
        position: relative;
        display: block;

        .video-icon {
            .ic-play {
                width: 50px;
                height: 50px;
            }
        }

        .b-thumb-cat {
            position: absolute;
            top: 20px;
            left: 20px;
            right: 20px;
            bottom: 20px;

            .badge {
                font-weight: 400;
                opacity: 0.9;
                margin: 2px;
                white-space: normal;
                text-align: left;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }

        @media #{$vpsmmobile} {
            img {
                width: 100%;
            }
        }
    }

    &-wrapper {
        display: flex;
        flex-flow: column;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 33.33%;
        flex-grow: 1;
        max-width: 33.33%;
        padding: 15px;

        .b-thumb-content-wrap {
            .b-thumb-img {
                position: relative;
                padding-top: 56.25%;
                overflow: hidden;
                height: auto;

                &.card-shadow {
                    overflow: visible;
                }

                picture {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }

        @media #{$vptabletL} {
            // padding: 20px;
            flex-basis: 50%;
            max-width: 50%;
        }

        @media #{$vptabletP} {
            // flex-basis: 50%;
        }

        @media #{$vpmobile} {
            // padding: 25px 10px;
            flex-basis: 100%;
            max-width: 100%;
        }

        @media #{$vpsmmobile} {}

        &.has--banner {
            position: relative;
            flex-basis: 100%;
            max-width: 100%;
            padding: 20px 0;

            @media #{$vpmobile} {
                display: none;
            }

            @media #{$vpsmmobile} {
                padding: 20px 0;
            }

            .b-thumb {
                &-img {
                    height: 530px;
                    padding: 0;

                    .b-thumb-cat {
                        top: 40px;
                        left: 40px;
                        right: 40px;
                        bottom: 40px;
                    }
                }

                &-content-wrap {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: linear-gradient(to right, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
                        z-index: 1;
                        opacity: 0.5;
                    }

                    &::after {
                        display: none;
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background: $dateColor;
                        height: 8px;
                        z-index: 9;
                    }

                    @media #{$vpsmmobile} {
                        margin-left: -30px;
                        margin-right: -30px;
                    }
                }

                &-content {
                    position: absolute;
                    left: 40px;
                    right: 40px;
                    bottom: 90px;
                    max-width: 100%;
                    z-index: 2;

                    p {
                        width: 100%;
                        max-width: 600px;
                        margin: 10px 0;
                        color: $white;
                    }

                    .thumbheading {
                        color: $white;
                        font-weight: bold;

                        a {
                            color: $white;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }

                    @media #{$vpsmmobile} {
                        left: 30px;
                        right: 30px;
                        bottom: 50px;
                    }
                }

                &-action {
                    position: absolute;
                    bottom: 60px;
                    left: 40px;
                    right: 50px;
                    z-index: 2;

                    .link-gray {
                        color: $white;

                        svg {
                            path {
                                fill: $white;
                            }
                        }

                        &:hover {
                            color: $linkSecondary;

                            svg {
                                path {
                                    fill: $linkSecondary;
                                }
                            }
                        }
                    }

                    @media #{$vpsmmobile} {
                        position: relative;
                        left: 0;
                        right: 0;
                        margin: 10px 0;
                    }
                }

                &-detail {
                    margin: 10px 0;

                    dl {
                        h5 {
                            color: $white;
                        }

                        p {
                            margin: 5px 0;
                        }
                    }
                }

                &-datetime {
                    time {
                        color: $white;
                    }
                    .date {
                        color: $white; 
                    }

                    span {
                        color: $white;
                    }
                    .js-tosvg {
                        path,
                        polygon {
                            fill: #fff !important;
                        }
                    }
                }
            }

            &.is--small {
                padding: 0;

                @media #{$vptabletP} {
                    padding: 0 0 25px;
                }

                // @media #{$vptabletL} {
                //     padding: 20px 40px 20px 0;
                // }

                .b-thumb-content {
                    .thumbheading {
                        font-size: 20px;
                        font-weight: 400;
                    }
                }

                .b-thumb {
                    &-img {
                        height: 420px;
                    }

                    &-content {
                        max-width: 340px;
                        left: 40px;
                        right: 40px;
                        bottom: 65px;
                    }

                    &-content-wrap {
                        &::before {
                            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
                            opacity: 1;
                        }
                    }
                }

                

                .b-thumb-action {
                    bottom: 40px;
                    left: 40px;
                    right: 40px;

                    .btn-link {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    &-content {
        max-width: 380px;
        margin: 15px 0 20px 0;

        .program-details {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px 0 0;

            &.txt-white {
                .program-duration,
                .program-fee {
                    strong {
                        color: $white;
                    }
                }
            }
        }

        .program-duration,
        .program-fee {
            display: flex;
            flex-direction: column;
            padding: 0 50px 0 0;

            strong {
                color: $black
            }
        }

        @media #{$vpmobile} {
            margin: 0px 0 18px;
        }

        h4,
        .thumbheading {
            font-size: 20px;
            font-weight: 700;
            color:$black-shade2;
            margin: 5px 0;

            a {
                color:$black-shade2;
                font-size: 20px;
                font-weight: bold;
                span{
                    display: block;
                    font-size: 40px;

                    &.sprayed {
                        background: $iBlue;
                        /*rtl:remove*/
                        margin: 0 0 5px;
                        /*rtl:raw:
                        margin: 0 0 10px;*/
                        
                        /*rtl:remove*/
                        padding: 13px 7px 5px 6px;
                        /*rtl:raw:
                        line-height: 1.3;
                        padding: 24px 7px 3px 6px;*/
                    }
                }
                @media #{$vpmobile} {
                    font-size: 16px; 
                }
            }
        }

        p {
            margin: 5px 0;
        }
    }

    &-detail {
        display: flex;
        flex-wrap: wrap;
        margin: 11px 0 0px;

        dl {
            flex-basis: 22%;
            flex-shrink: 0;
            margin-bottom: 0;
            min-width: 120px;

            dt {
                h5 {
                    text-transform: capitalize;
                }
            }

            @media #{$vptabletL} {
                flex-basis: 50%;
            }
        }
    }

    &-action {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media #{$vpmobile} {
            justify-content: space-between;
        }

        .btn {
            display: flex;
            align-items: center;
            /*rtl:remove*/
            margin-right: 25px;
            margin-bottom: 10px;
            /*rtl:raw:
            margin-left: 15px;*/
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {
                margin-right: 0;
                margin-left: 0;
                margin-top: 0;
            }
            // @media #{$vpmobile} {
            //     &.btn-link{
            //         /*rtl:remove*/
            //         margin-top: 20px;
            //         /*rtl:raw:
            //          margin-top: 0px;*/
                   
            //     }
            // }
        }
    }

    &-datetime {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0 15px;

        @media #{$vpmobile} {
            margin: 15px 0 6px;
        }

        time {
            display: inline-block;

            &.date {
                font-weight: 400;
                color: #7b8a95;
                font-size: 14px;
            }
        }

        span {
            display: inline-block;
            margin: 0 10px;
            color: $headingColor;
        }
    }

    &-sty2 {
        .b-thumb-content {
            max-width: 100%;
        }
    }
}

.sec-thumbs-handler {
    &:not(.sec-whatshappening) {
        h1 {
            margin-bottom: 30px;
        }

        .thumb-wrapper .b-thumb-wrapper {
            padding-top: 0;
        }
    }

    .b-thumb-wrapper {
        @media #{$vpSdesktop} {
            flex-basis: 50%;
        }

        &:not(.u-mobile-only) {
            &+.b-thumb-wrapper+.b-thumb-wrapper {
                display: none;
            }
        }

        .b-thumb-wrapper-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .b-thumb-datetime {
                margin: 0;
            }
        }

        .b-thumb-action {
            .btn {
                padding-bottom: 10px;
            }
        }
    }

    h1 {
        margin-bottom: 18px;
    }

    .col-12>p {
        margin: 0;
    }

    .btn.btn-primary {
        margin-top: 28px;
    }

    .thumb-wrapper {
        margin-bottom: 0;

        .mod--pt {
            padding-top: 0;

            @media #{$vptabletP} {
                padding-top: 50px;
            }
        }

        &.slick-initialized {
            .b-thumb-wrapper {
                @media #{$vpmobile} {
                    padding: 0 6px;
                }
                
                // .b-thumb-wrapper-inner {
                //     @media #{$vpmobile} {
                //         margin: 0 5px;
                //     }
                // }
            } 
        }

        .b-thumb-wrapper {
            max-width: 50%;
            padding: 0 15px;
        }
    }

    .d-flex {
        margin-top: 40px;
    }

    @media #{$vptabletP} {
        .btn.btn-primary {
            margin-bottom: 30px;
        }
    }

    @media #{$vpmobile} {
        overflow: hidden;

        .btn.btn-padded {
            min-width: 0;
        }

        .btn.btn-primary {
            margin-bottom: 0px;
            margin-top: 0;
        }

        h1 {
            margin-bottom: 15px;
        }

        .thumb-wrapper {
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0;
            width: 100%;

            .slick-list {
                overflow: visible;
            }
        }

        .js-mob-slider-1 {
            display: block !important;
            width: auto;
            margin: 0 -25px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .thumb-wrapper {
            .b-thumb-wrapper {
                max-width: 100%;
            }
        }

        .b-thumb-wrapper {
            padding: 0 5px;
            flex-basis: 100%;
            max-width: 100%;
        }

        .b-thumb-wrapper-inner {
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }

        .b-thumb-datetime {
            margin: 0px 0 4px;
        }

        .b-thumb-action {
            padding: 10px 24px 5px;
        }

        .b-thumb-content {
            padding: 16px 24px 0;
            margin-bottom: 0;

            .thumbheading {
                margin: 0;
                min-height: 40px;
            }
        }

        .d-flex,
        .btn.btn-primary.u-mobile-only {
            margin-top: 31px;
        }
    }
}

.sec-featured-programs-new {
    .title {
        margin: 0 0 45px;

        @media #{$vptabletP} {
            margin: 0 0 20px;
        }
    }

    &.sec-thumbs-handler .btn.btn-primary {
        margin-top: 0;
        margin-right: 15px;

        +.btn-link {
            margin-left: 0;
        }
    }

    @media (max-width: 1199px) {

        .b-thumb-wrapper.has--banner .b-thumb-img .b-thumb-cat,
        .b-thumb-wrapper.has--banner .b-thumb-content {
            left: 30px;
        }

        .b-thumb-wrapper.has--banner .b-thumb-action {
            left: 30px;
            bottom: 50px;
        }
    }

    @media (max-width: 991px) {
        &.sec-thumbs-handler .btn.btn-primary {
            margin-bottom: 0;
        }
    }

    @media (max-width: 768px) {
        &.sec-thumbs-handler .b-thumb-action {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
.m-t-b {
    margin: 20px 0 0;
}

.b-thumb-datetime {
    color: $black-shade2;

    @media (max-width: 767px){
        margin: 15px;
    }

    svg {
        path {
            fill: $black !important;
        }
    }

    .time {
        font-size: 12px;
        color: $black-shade2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-basis: 30%;
    }

    .date {
        font-size: 12px;
        color: $black-shade2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
    }

    svg {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin: 0 10px 0 0;
    }

    .badge-wrap {
        span {
            margin: 0;
            padding: 5px 10px;
            border-radius: 5px;
            background: $badgeGray;
            color: $black-shade2;
            font-weight: normal;
        }
    }
}
.related-event--padding {
    padding-right: 50px;
    @media #{$vptabletL} {
        padding-right: 0;
    }
}
.space-content {
    .space-detail {
        p {
            font-size: 16px;
            @media #{$vpmobile} {
                font-size: 13px;
            }
        }
    }
    .chips {
        margin: 15px 0;
        .badge-blank {
            border-radius: 6px;
            border: 1px solid $iBlue;
            color: $iBlue;
            font-size: 14px;
        }  
    }
    .b-thumb-action {
        margin-top: 30px;
    }
}
.sec-space-fancybox {
    .fancybox-wrap {
        .c-customBox {
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                @media #{$vptabletL} {
                    height: 250px;
                }
                @media #{$vpmobile} {
                    height: 100%;
                }
            }
        }
    }
}
.content--bolder {
    font-weight: bolder !important;
}
.b-thumb-content {
    .program-details {
        &.txt-white {
           .program-duration,
            .program-fee {
                strong {
                    font-size: 16px;
                }
            }
        }
    }  
}
.content--normal {
    font-weight: normal;
}
.plyr--full-ui.plyr--video .plyr__control--overlaid {
    background: $iSecondary;
}
.plyr--video .plyr__control:hover {
    background: $iSecondary;
}
.plyr__volume input[type=range] {
    color: $iSecondary;
}
.plyr__progress__buffer, .plyr__progress input[type=range] {
    color: $iSecondary;
}
.plyr--video {
    margin-top: 30px;
}
