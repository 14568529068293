.sec-daily-events {
    background-size: cover;
    background-position: center center;
    position: relative;
    color: $iPrimary;
    background-image: none !important;

    .container {
        position: relative;
    }
}

.c-text-daily-events {
    position: relative;
    padding-right: 70px;
    margin-top: 20px;

    @media #{$vptabletL} {
        padding-right: 0;
    }

    @media #{$vptabletP} {
        padding: 0;
        margin-left: auto;
        margin-right: auto;

        .slickIndicator {
            margin: 0;
            max-width: 100%;
        }
    }

    .c-heading-context {
        .c-heading {
            margin-top: 0px;
            color: $white;
        }

        p {
            color: $white;

            @media (max-width: 767px) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.c-heading-context {
    &.context--discover {
        max-width: 500px;
        margin-top: 80px;

        @media #{$vptabletP} {
            max-width: 100%;
            margin-top: 40px;
            margin-bottom: 0;
        }

        @media (max-width: 767px) {
            margin-top: 40px; 
        }

        .c-heading {
            margin-top: 0px;
        }
    }

    &.context--heading {
        max-width: 620px;
        padding-left: 100px;

        .c-heading {
            margin-top: 0px;
            margin-bottom: 10px;
        }
    }

    .c-heading {
        margin-top: 20px;
        margin-bottom: 12px;
    }

    p {
        /* margin-top: 30px;
		margin-bottom: 30px; */
    }

    .cta-btn {
        margin-top: 10px;
    }
}
.c-event-detail {
    p {
        margin: 15px 0 25px;
    }

    .label {
        display: inline-block;
        padding: 4px 12px 6px;
        background: #4c4c4c;
        margin: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .event-time {
        display: flex;
        margin-top: 20px;
        width: 100%;

        .lc {
            width: 15px;

            +.rc {
                padding-left: 10px;
            }
        }


        .icon {
            width: 15px;
            height: 18px;
            display: inline-block;
        }

        p {
            vertical-align: top;
            width: 100%;
            margin: 0;
            line-height: 1.4;
            /* display: inline-block;
		    border-right: 1px solid $white;
		    padding-right: 10px;
		    margin-right: 10px;
		    width: auto;

		    &:last-child{
		    	border-right: none;
		    	margin-right: 0;
		    	padding-right: 0;
		    } */
        }
    }

    .event-detail {
        margin: 0 0 20px 0;

        @media #{$vptabletP} {
            margin-bottom: 0;
        }

        span {
            color: $iSecondary;
        }

        .c-heading {
            margin: 12px 0 10px;
            padding: 0;

            @media #{$vpmobile} {
                +.label {
                    margin-top: 0;
                    margin-bottom: 400px;
                }

                span {
                    font-size: 14px;
                }
            }

            @media (max-width: 374px) {
                span {
                    font-size: 12px;
                }
            }

            +.event-time {
                margin-bottom: 20px;
            }
        }
    }
}

.c-img-swipper {
    position: relative;
    height: 520px;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 20px;

    @media #{$vpMXdesktopMax} {
        padding-left: 0;
    }

    @media #{$vptabletL} {
        height: 360px;
    }

    .js-img-swipper {
        position: relative;
    }

    &.swipper--header {
        max-width: 600px;
    }

    * {
        height: 100%;
    }

    .sliderArrow {
        position: absolute;
        bottom: 20px;
        top: auto;
        left: auto;
        right: 20px;
        z-index: 5;
        height: auto;

        @media #{$vptabletP} {
            right: 20px;
        }

        .slick-arrow {
            &.slick-prev {
                opacity: 0.5;
            }

            background-color: $white;

            .toSvg {

                path,
                polygon {
                    fill: #222222 !important;
                }
            }
        }
    }

    .img-swipper {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        transition: margin ease-in-out .2s, transform ease-in-out .2s;
        transform: translateZ(0);
        margin-left: 20px;
        opacity: 0;
        max-width: 100%;
        pointer-events: none;
        will-change: opacity, left, right;

        .transform-text.bg-white {
            background-color: $white;
            width: 450px;

            &:before {
                content: "";
                background-color: $white;
                width: 50px;
                position: absolute;
                height: 70px;
                left: -50px;
                top: 0;
            }

            @media (max-width: 1199px) {
                width: 500px;
            }

            @media (max-width: 991px) {
                display: none;
            }

        }

        @media (max-width: 767px) {
            max-width: 95%;
        }

        img {
            opacity: 0.5;
            transition: opacity .3s;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:nth-child(1) {
            z-index: 5;
            opacity: 1;

            img {
                opacity: 1.0;
            }

            margin-left: 0px;
            pointer-events: auto;
        }

        &:nth-child(2) {
            z-index: 4;
            opacity: 1;

            img {
                opacity: 0.5;
            }

            margin-left: 10px;
            transform: translateZ(0) translateY(10px);
        }

        &:nth-child(3) {
            z-index: 3;
            opacity: 1;

            img {
                opacity: 0.25;
            }

            margin-left: 20px;
            transform: translateZ(0) translateY(20px);
            transition: none;
        }

        picture {
            display: block;
            position: relative;
            transition: transform .2s;
            transform: scale(1);
            transform-origin: center center;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #363636;
                z-index: 0;
            }

            img {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &.moving-out,
        &.is--pressed {
            picture {
                transform: scale(.95);
                transform-origin: center center;
            }

            img {}
        }
    }
}

.c-text-slider {
    position: relative;

    .slickIndicator {
        display: block;

        @media #{$vpmobile} {
            display: none;
        }
    }
}

@media (max-width: 682px) {
    .c-img-swipper {
        .sliderArrow {
            right: 5%;
            left: auto;
        }
    }
}

@media #{$vpmobile} {
    .c-img-swipper {
        height: 340px;

        .sliderArrow {
            display: block;
            position: absolute;
            bottom: 0;
            left: 477px;
            z-index: 5;
        }
    }
}

.c-image-wrapper {
    position: relative;

    @media #{$vptabletL} {
        margin-top: 40px;
    }

    @media #{$vpmobile} {
        margin-top: 25px;
    }

    &.image-wrapper--no-count {
        .ImageSliderIndicator {
            display: none !important;
        }
    }

    img {
        position: relative;
        z-index: 2;
    }

    &:after {
        position: absolute;
        left: 50%;
        right: calc((-100vw - 1170px) / 2);
        top: 0;
        bottom: 0;
        background-color: yellow;
        content: "";
        z-index: 1;

        @media #{$vpdesktop} {
            right: calc((-100vw - 1110px) / 2);
        }

        @media #{$vptabletL} {
            right: calc((-100vw - 930px) / 2);
        }

        @media #{$vptabletP} {
            right: calc((-100vw - 720px) / 2);
        }

        @media #{$vpmobile} {
            right: -15px;
        }
    }

    &.wrap-flip {
        &:after {
            position: absolute;
            right: 50%;
            left: calc((-100vw - 1170px) / 2);
            top: 0;
            bottom: 0;
            background-color: yellow;
            content: "";
            z-index: 1;

            @media #{$vpdesktop} {
                left: calc((-100vw - 1110px) / 2);
            }

            @media #{$vptabletL} {
                left: calc((-100vw - 930px) / 2);
            }

            @media #{$vptabletP} {
                left: calc((-100vw - 720px) / 2);
            }

            @media #{$vpmobile} {
                left: -15px;
            }
        }
    }

    &.extra-wrap {
        &:after {
            position: absolute;
            left: 50%;
            right: calc((-100vw - 1170px) / 2);
            top: -50px;
            bottom: -50px;
            background-color: yellow;
            content: "";
            z-index: 1;

            @media #{$vpdesktop} {
                right: calc((-100vw - 1110px) / 2);
            }

            @media #{$vptabletL} {
                right: calc((-100vw - 930px) / 2);
            }

            @media #{$vptabletP} {
                right: calc((-100vw - 720px) / 2);
            }

            @media #{$vpmobile} {
                right: -15px;
            }
        }

        &.bottom-wrap-less {
            &:after {
                bottom: 50px;
                height: 100%;
            }
        }

        &.bottom-wrap-75 {
            &:after {
                bottom: 50px;
                height: 75%;
                left: 110px;
                top: -40px;
            }
        }
    }

    &.wrap-left-align {
        &:after {
            position: absolute;
            right: 0;
            left: calc((-100vw - 1170px) / 2);
            top: 0;
            bottom: 0;
            background-color: $iBlue;
            content: "";
            z-index: 1;

            @media #{$vpdesktop} {
                left: calc((-100vw - 1110px) / 2);
            }

            @media #{$vptabletL} {
                left: calc((-100vw - 930px) / 2);
            }

            // @media #{$vptabletP} {
            //     left: calc((-100vw - 720px) / 2);
            // }

            @media #{$vptabletP} {
                left: calc((-100vw - 720px) / 2);
                right: calc((-100vw - 720px) / 2);
            }
        }
    }

    &.no-wrapalign {
        &:after {
            display: none;
        }
    }
}

.c-sec-content-header {
    .c-content-header {
        margin: 60px 0 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(2n) {
            .c-row {
                flex-direction: row-reverse;
            }

            .c-image-wrapper:after {
                right: 50%;
                left: -webkit-calc((-100vw - 1170px) / 2);
                left: calc((-100vw - 1170px) / 2);

                @media (max-width: 767px) {
                    left: -30px;
                    right: 40%;
                }
            }

        }
    }
}

.c-content-header {
    padding: 50px 0;

    &.alignContent {
        padding: 0;

        .c-image-wrapper {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .c-row {
            align-items: center;
        }
    }

    &.img-left-align {
        padding: 0;

        .c-image-wrapper {
            padding-left: 0;
            
            @media (max-width: 991px) {
                padding-left: 0;
                margin-top: 0;
            }
        }
    }
}

.image-slider-wrap {
    position: relative;
    z-index: 2;
    padding-top: 60px;
    @media (max-width: 767px) {
        padding-top: 20px;
    }
    .c-image-slider {
        p {
            color: $white;
            display: block;
            // height: 0;
            // opacity: 0;
            height: 40px;
            overflow: hidden;
            margin: 10px 0;
            margin-right: 100px;
        }
        @media (max-width: 767px) {
            margin-right: 20px;
        }
    }

    .ImageSliderNav {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 0;

        .slick-arrow {
            width: 76px;
            height: 65px;
            background-color: $white;
            display: inline-block;
            border: none;
            box-shadow: none;
            outline: none;
            cursor: pointer;
            padding: 0;

            .toSvg {
                width: 11px;
                height: 15px;

                polygon,
                path {
                    fill: $black !important;
                }
            }

            &.slick-disabled {
                pointer-events: none;
                opacity: 0.5;
            }

            @media #{$vptabletL} {
                width: 76px;
                height: 65px;

                .toSvg {
                    width: 10px;
                    height: 13px;
                }
            }

            @media #{$vptabletP} {
                width: 61px;
                height: 51px;

                .toSvg {
                    width: 9px;
                    height: 10px;
                }
            }
        }
    }

    .ImageSliderIndicator {
        display: block;
        font-size: 36px;
        line-height: 1;
        color: $white;
        margin-bottom: 5px;

        .slick-dots {
            font-size: inherit;
        }

        ul {
            padding: 0;
            list-style: none;
            margin: 0;

            li {
                display: none;
                color: $iLight-green;

                small {
                    font-size: 100%;
                    color: $white;
                }

                &.slick-active {
                    display: block;
                }
            }
        }
    }
}

.mbl-white {
    @media #{$vpmobile} {
        color: #ffffff;
    }
}