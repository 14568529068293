.event-spaces {
    .about-content {
        &.spaces--btn {
            width: 100%;
            text-align: center;

            .btn {
                margin: 0;
            }
        }
    }
}

.thumb-wrapper {
    &.col--4 {
        .b-thumb-wrapper {
            flex-basis: 25%;
            max-width: 25%;
            padding: 20px 15px;

            @media #{$vptabletL} {
                flex-basis: 50%;
                max-width: 50%;
            }

            // @media #{$vptabletP} {
            //     flex-basis: 50%;
            //     max-width: 50%;
            // }

            @media #{$vpmobile} {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    &.slick-initialized {
        display: block;

        .b-thumb-wrapper {
            flex-basis: auto;
            max-width: 100%;
        }

        .b-thumb-content-wrap {
            .b-thumb-img.card-shadow {
                height: 190px;
                display: block;
            }
        }

        &.col--4 {
            .b-thumb-wrapper {
                padding: 20px 10px;
            }
        }

        &.nth-even-odd {
            .slick-slide {
                &:nth-child(even) {
                    .card-shadow {
                        &:before {
                            transform: translate(-10px, 10px);
                        }
                    }
                }
            }
        }
    }
}
