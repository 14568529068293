html {
    direction: ltr;
    width: 100%;
    // scroll-behavior: smooth;
    // overscroll-behavior: contain;
    &.browserIos {
        overflow-x: hidden;
    }
}

body {
    background: $white;
    /*rtl:remove*/
    font-family: $primaryFont;
    /*rtl:raw:
    font-family: '29LT Ravi';*/
    @include type($iPrimary, 14px, 400, 1.43, 0, 0);
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    left: 0;
    opacity: 0;

    &.page-loaded {
        opacity: 1;
    }
}

.body-wrapper {
    overflow: hidden;   
}

p {
    @include type($iPrimary, 14px, 400, 1.43, 10px 0, 0);

    small {
        font-size: 12px;
    }

    i {
        font-style: italic;
    }
    @media #{$vpmobile} {
        font-size: 13px;
    }
}

h1,
h2,
h3,
h4,
h5 {
    position: relative;
    color: $iSecondary;;
    unicode-bidi: bidi-override;
    font-weight: bold;
    .sprayed {
        background-color: $iBlue;
        /*rtl:remove*/
        padding: 7px 6px 1px 3px;
        /*rtl:raw:
        padding: 19px 7px 5px 6px*/
        position: relative;
         /*rtl:remove*/
         line-height: 1;
         /*rtl:raw:
         line-height: 1.2;*/
        /*rtl:remove*/
        margin-top: 10px;
        margin-bottom: 10px;
        /*rtl:raw:
        margin-top: 19px*/
        &.spray--green {
            background-color: $iSecondary;
        }
    }

    &.txt-white,
    .txt-white &,
    .txt-white {
        color: $white;
        .hdng-icon {
            path {
                fill: $white;
            }
        }
    }
    .hdng-icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        position: relative;
        top: -2px;

        path {
            fill: $headingColor;
        }
        .txt-white,
        .txt-white & {
            path {
                fill: $white;
            }
        }
    }

    &.color-blue {
        color:$iBlue;
    }
    
    &.color-pink {
        color:$iPink;
    }
    
    &.color-black {
        color:$black;
    }
    
    &.color-white {
        color:$white;
    }
}

h1,
.h1 {
    font-size: 50px;
    font-weight: 900; //black
    text-transform: uppercase;
    /*rtl:remove*/
    line-height: 1;
    /*rtl:raw:
    line-height: 1.2;*/
    margin: 10px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{$vptabletL} {
        font-size: 40px;
    }
    @media #{$vpmobile} {
        font-size: 35px;
    }

    &.align-center {
        text-align: center;
        align-items: center;
    }

    small {
        display: block;
        font-size: 20px;
        font-weight: 400; //Medium
        letter-spacing: 0;
        color: $black;

        @media #{$vpmobile} {
            font-size: 20px;
        }
        @media #{$vpsmobile} {
            font-size: 16px;
        }
    }

    &:before,
    &:after {
        content: "";
        background-color: $iSecondary;
        width: 9px;
        height: 9px;
        display: block;
        display: none;
        position: absolute;
        left: -49px;
        top: -4px;

        @media #{$vptabletL} {
            left: -39px;
        }

        @media #{$vptabletP} {
            display: none;
        }
    }

    &:after {
        display: none;
        width: 22px;
        height: 22px;
        left: -40px;
        top: 5px;

        @media #{$vptabletL} {
            left: -30px;
        }
    }
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        display: block;
    }
    @supports (-ms-ime-align:auto) {
        display: block;
    }
}

h2,
.h2 {
    font-size: 36px;
    font-weight: 900;
    text-transform: uppercase;
     /*rtl:remove*/
     line-height: 1;
     /*rtl:raw:
     line-height: 1.2;*/
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media #{$vpmobile} {
        font-size: 24px;
    }
    @media #{$vpxsmobile} {
        font-size: 22px;
    }

    &.align-center {
        text-align: center;
        align-items: center;
    }

    span {
        display: block
    }

    small {
        display: block;
        font-size: 22px;
        font-weight: 400;

        @media #{$vpmobile} {
            font-size: 16px;
        }
        @media #{$vpxsmobile} {
            font-size: 14px;
        }
    }

}

h3,
.h3 {
    font-size: 24px;
     /*rtl:remove*/
     line-height: 1.08;
     /*rtl:raw:
     line-height: 1.2;*/
    margin: 15px 0;

    span {
        display: block;
    }
    @media #{$vpmobile} {
        font-size: 20px;
    }
    @media #{$vpxsmobile} {
        font-size: 18px;
    }
}

h4,
.h4 {
    font-size: 20px;
    line-height: 1.3;
    margin: 10px 0;

    @media #{$vpmobile} {
        font-size: 16px;
    }
    @media #{$vpxsmobile} {
        font-size: 14px;
    }
}

h5,
.h5 {
    font-size: 16px;
    line-height: 1.125;
    margin: 10px 0;
    text-transform: uppercase;

    span {
        color: $iPink;
        text-transform: none;
        font-weight: 400; // Medium
    }
}

.align-center,
.text-center {
    text-align: center;
    align-items: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        text-align: center;
        align-items: center;
    }
}

time {
    font-size: 14px;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

*,
*:after,
*:before {
    outline: none;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none !important;
    outline: none !important;
    color: $linkSecondary;

    &:hover {
        color: $iBlue;
    }
}

svg {
    transform: translateZ(0);
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.o-hidden {
    overflow: hidden;
    height: 100%;
}

.dnone {
    display: none !important;
}

.u-pos-s {
    position: static;
}

.u-pos-r {
    position: relative;
}

.pl {
    &-20 {
        padding-left: 20px;
    }

    &-25 {
        padding-left: 25px;
    }

    &-40 {
        padding-left: 40px;
    }

    &-50 {
        padding-left: 50px;
    }
}

.pr {
    &-20 {
        padding-right: 20px;
    }

    &-25 {
        padding-right: 25px;
    }

    &-40 {
        padding-right: 40px;
    }

    &-50 {
        padding-right: 50px;
    }
}

.pt {
    &-20 {
        padding-top: 20px;
    }

    &-25 {
        padding-top: 25px;
    }

    &-40 {
        padding-top: 40px;
    }

    &-50 {
        padding-top: 50px;
    }
}

.pb {
    &-20 {
        padding-bottom: 20px;
    }

    &-25 {
        padding-bottom: 25px;
    }

    &-40 {
        padding-bottom: 40px;
    }

    &-50 {
        padding-bottom: 50px;
    }
}

.ml {
    &-20 {
        margin-left: 20px;
    }

    &-25 {
        margin-left: 25px;
    }

    &-40 {
        margin-left: 40px;
    }

    &-50 {
        margin-left: 50px;
    }
}

.mr {
    &-20 {
        margin-right: 20px;
    }

    &-25 {
        margin-right: 25px;
    }

    &-40 {
        margin-right: 40px;
    }

    &-50 {
        margin-right: 50px;
    }
}

.mt {
    &-20 {
        margin-top: 20px;
    }

    &-25 {
        margin-top: 25px;
    }

    &-40 {
        margin-top: 40px;
    }

    &-50 {
        margin-top: 50px;
    }
}

.mb {
    &-20 {
        margin-bottom: 20px;
    }

    &-25 {
        margin-bottom: 25px;
    }

    &-40 {
        margin-bottom: 40px;
    }

    &-50 {
        margin-bottom: 50px;
    }
}

.toSvg {
    max-width: 100%;
    max-height: 100%;

    polygon,
    path {
        transition: fill 0.35s linear;
    }
}

input::-ms-clear {
    display: none;
}

.primaryFont {
    /*rtl:remove*/
    font-family: $primaryFont;
    /*rtl:raw:
    font-family: 'Frutiger LT Arabic 55 Roman';*/
}


.grayScale {
    >img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all 0.5s ease;
    }

    &:hover {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }

    @media #{$vptabletP} {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }
}

.txt-white {
    color: $white;
}

.txt-black {
    color: $black
}

.txt-primary {
    color: $iPrimary
}

.txt-secondary {
    color: $iSecondary
}

.txt-gray {
    color: $iGray
}

.txt-theme-black {
    color: $iBlack
}

.txt-linksecondary {
    color: $linkSecondary
}

.txt-lightgray {
    color: $lightGray
}

.txt-headingcolor {
    color: $headingColor
}

.bg-white {
    background-color: $white;
}

.bg-black {
    background-color: $black
}

.bg-primary {
    background-color: $iPrimary
}

.bg-secondary {
    background-color: $iSecondary
}

.bg-gray {
    background-color: $iGray
}

.bg-theme-black {
    background-color: $iBlack
}

.bg-linksecondary {
    background-color: $linkSecondary
}

.bg-lightgray {
    background-color: $lightGray
}

.bg-headingcolor {
    background-color: $headingColor
}

.bg-blue {
    background: $iBlue;
}

.btn {
    font-size: 15px;
    font-weight: 400;
    /*rtl:remove*/
    padding: 14px 60px 12px 20px;
    /*rtl:raw:
    padding: 16px 20px 10px 60px;*/
    border-radius: 0;
    text-transform: uppercase;
    text-align: left;
    transition: all 0.35s ease 0s;
    @media #{$vpmobile} {
        font-size: 14px;
        /*rtl:remove*/
        padding: 12px 50px 10px 15px;
        /*rtl:raw:
        padding: 12px 15px 7px 50px;*/
    }

    &.btn-md {
        padding: 7px 25px;
    }

    &.btn-primary {
        position: relative;
        background-color: $iSecondary;
        border-color: $iSecondary;
        box-shadow: $buttonShadow-green;

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &.focus,
        &:focus {
            box-shadow: 0 0 2px 0.2rem rgba($iSecondaryDark, 0.3);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:hover,
        &.focus,
        &:focus {
            background-color: $iSecondaryDark;
            border-color: $iSecondaryDark;
        }
    }
    &.btn-blue {
        position: relative;
        background-color: $iBlue;
        border-color: $iBlue;
        box-shadow: $buttonShadow-blue;
        color: $white;

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &.focus,
        &:focus {
            box-shadow: 0 0 2px 0.2rem rgba($iBlueDark, 0.3);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:hover,
        &.focus,
        &:focus {
            background-color: $iBlueDark;
            border-color: $iBlueDark;
        }
    }

    &.btn-pink {
        position: relative;
        background-color: $iPink;
        border-color: $iPink;
        box-shadow: $buttonShadow-pink;
        color: $white;

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &.focus,
        &:focus {
            box-shadow: 0 0 2px 0.2rem rgba($iPinkDark, 0.3);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:hover,
        &.focus,
        &:focus {
            background-color: $iPinkDark;
            border-color: $iPinkDark;
        }
    }

    &.btn-red {
        position: relative;
        background-color: $iRed;
        border-color: $iRed;
        box-shadow: $buttonShadow-red;
        color: $white;

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &.focus,
        &:focus {
            box-shadow: 0 0 2px 0.2rem rgba($iRedDark, 0.3);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:hover,
        &.focus,
        &:focus {
            background-color: $iRedDark;
            border-color: $iRedDark;
        }
    }
    &.btn-transparent {
        position: relative;
        background-color: transparent;
        border-color: $black;
        color: $black;

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &.focus,
        &:focus {
            box-shadow: none;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        &:hover,
        &.focus,
        &:focus {
            background-color: $iPinkDark;
            border-color: $iPinkDark;
        }
        &:after {
            display: none;
        }
        .file-upload {
            width: 20px;
            height: 20px;
            margin-right: 20px;
            path, 
            polygon {
                fill: $black;
            }
        }
        &.btn-white {
            border-color: $white;
            color: $white;
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active,
            &:hover,
            &.focus,
            &:focus {
                background-color: rgba($white, 0.1);
                border-color: rgba($white, 1);
            }
        }
    }
    &.btn-padded {
        min-width: 310px;
    }

    &.btn-link {
        color: #f16669;
        padding: 12px 0;
        font-weight: 400;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px 0 0;
        &:after {
            display: none;
        }
        
        &:after {
            display: none;
        }

        svg,
        img {
            width: 17px;
            height: 13px;
            display: inline-block;
            margin-left: 15px;
            transition: transform 0.3s ease-out;

            path {
                fill: #f16669;
                stroke: none;
                transition: fill .15s ease-in-out;
            }

            /*rtl:raw:
            transform: rotateY(180deg)*/
        }

        &:hover,
        &.focus,
        &:focus {
            color: #c20828;

            svg {
                path {
                    fill: #c20828;
                }
            }
        }

        &.link-blue {
            color: $iBlue;

            svg {
                path {
                    fill: $iBlue;
                }
            }

            &:hover,
            &.focus,
            &:focus {
                color:$iBlueDark;

                svg {
                    path {
                        fill:$iBlueDark;
                    }
                }
            }
        }

        &.link-black {
            color: $headingColor;

            svg {
                path {
                    fill: $headingColor;
                }
            }

            &:hover,
            &.focus,
            &:focus {
                color: $iSecondary;

                svg {
                    path {
                        fill: $iSecondary;
                    }
                }
            }
        }

        &.link-green {
            color: $iSecondary;

            svg {
                path {
                    fill: $iSecondary;
                }
            }

            &:hover,
            &.focus,
            &:focus {
                color: $iSecondaryDark;

                svg {
                    path {
                        fill: $iSecondaryDark;
                    }
                }
            }
        }

        &.link-pink,
        &.link-red {
            color: $iRed;

            svg {
                path {
                    fill: $iRed;
                }
            }

            &:hover,
            &.focus,
            &:focus {
                color: $linkSecondary;

                svg {
                    path {
                        fill: $linkSecondary;
                    }
                }
            }
        }

        &.link-white {
            color: $white;

            svg {
                path {
                    fill: $white;
                }
            }

            &:hover,
            &.focus,
            &:focus {
                color: $linkSecondary;

                svg {
                    path {
                        fill: $linkSecondary;
                    }
                }
            }
        }
        &:hover {
            svg,
            img {
                /*rtl:remove*/
                transform: translateX(4px); 
                /*rtl:raw:
                transform: translateX(-4px) rotateY(180deg)*/
            } 
        }
    }

    &.btn-link-1 {
        padding: 0;
        font-size: 14px;
        color: $resetColor;
        border-bottom: 1px solid $borderLine;
    }

    &:after{
        content: "";
        display: block;
        width: 10px;
        height: 12px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        /*rtl:raw:
        transform: translateY(-50%) rotateY(180deg)*/
        background: url(../img/icons/ic-button-right-arrow.svg) no-repeat center / contain;
        transition: transform 0.3s ease-out;
    }

    &.no--arrow {
        /*rtl:remove*/
        padding: 14px 20px 12px;
        /*rtl:raw:
        padding: 16px 20px 10px;*/
        @media #{$vpmobile} {
            /*rtl:remove*/
            padding: 12px 15px 10px 15px;
            /*rtl:raw:
            padding: 12px 15px 7px 15px;*/
        }
        &:after {
            display: none;
        }
        &.file-dwonload {
            &:after {
                display: block;
            }
        }
    }
    &.file-dwonload {
        padding-left: 60px;
        text-transform: none;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            left: 17px;
            top: 50%;
            transform: translateY(-50%);
            background: url(../img/icons/ic-download.svg) no-repeat center / contain;
            transition: transform 0.3s ease-out;
        }
    }
    &:hover {
        &:after {
            transform: translate(4px , -50%); 
            /*rtl:raw:
             transform: translate(-4px , -50%) rotateY(180deg)*/
        }
    }
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    .ic-header {
        display: block;
        width: 100%;
        height: 40px;
        flex: 0 0 40px;
        fill: #00733a;
        margin: 2px 20px 8px 0;

        @media #{$vptabletL} {
            height: 32px;
            flex: 0 0 32px;
            margin-right: 15px;
        }

        @media #{$vpmobile} {
            height: 22px;
            flex: 0 0 26px;
            margin-right: 10px;
        }

        @media #{$vpxsmobile} {
            height: 22px;
            flex: 0 0 23px;
            margin-right: 10px;
        }
    }

    &.color-black {
        .ic-header {
            fill: $black;

            polygon,
            path {
                fill: $black;
            }
        }
    }

    &.color-white {
        .ic-header {
            fill: $white;

            polygon,
            path {
                fill: $white;
            }
        }
    }

    &.color-blue {
        .ic-header {
            fill: $iBlue;

            polygon,
            path {
                fill: $iBlue;
            }
        }
    }

    &.color-pink {
        .ic-header {
            fill: $iPink;

            polygon,
            path {
                fill: $iPink;
            }
        }
    }
}

button, .btn{
    &:focus{
        box-shadow: none;
        outline: none;
    }
}

.btn-close {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    transform: rotateZ(45deg);
    .txt{
        display: none;
    }
    i {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background: $iconColor;
        transform: translateY(-50%);

        &:nth-child(2) {
            top: 0;
            left: 50%;
            height: 100%;
            width: 2px;
            transform: translateX(-50%);
        }
    }
}

.chips {
    margin-bottom: -10px;
}
.badge {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 4px;

    &-light {
        background-color: rgba($white, 0.9);
        color: $black-shade1;
    }

    &-blank {
        font-weight: 400;
        padding: 6px 16px;
        margin-right: 10px;
        border-radius: 10px;
        border: 2px solid $iPrimary;
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0;
        }

        @media #{$vptabletP} {
            margin-bottom: 10px;
        }
    }
}

.container-expanded {
    @media screen and (min-width: 1360px) {
        max-width: 1200px;
    }

    @media #{$vpMXdesktopMin} {
        max-width: 1500px;
    }
}

.container {
    padding-left: 10px;
    padding-right: 10px;

    @media #{$vpmobile} {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media #{$vpxsmobile} {
        padding-left:  15px;
        padding-right: 15px;
    }
}

.row {
    margin-left: -15px;
    margin-right: -15px;

    &.no-gutters {
        margin-right: 0;
        margin-left: 0;
        [class*=col-] {
            padding: 0;
        }
    }

    [class*=col-] {
        padding: 0 15px;
    }

    &.pd_10 {
        margin-left: -10px;
        margin-right: -10px;

        [class*=col-] {
            padding: 0 10px;
        }
    }

    &.pd_20 {
        margin-left: -20px;
        margin-right: -20px;

        [class*=col-] {
            padding: 0 20px;
        }
    }

    @media #{$vpmobile} {
        &.pd-mobile_10 {
            margin-left: -10px;
            margin-right: -10px;

            [class*=col-] {
                padding: 0 10px;
            }
        }
    }
}

::-moz-selection {
    background: rgba($iSecondary, 0.3);
    text-shadow: none;
}

::selection {
    background: rgba($iSecondary, 0.3);
    text-shadow: none;
}

.brand-logo {
    max-width: 100%;
    max-height: 100%;

    .emblem {
        path {
            fill: #E51430;
            transition: fill 0.35s ease 0s;
        }
    }

    .bmbk,
    .arbmbk {
        path {
            fill: #505A61;
            transition: fill 0.35s ease 0s;
        }
    }

    .abudhabi,
    .arabudhabi {
        path {
            fill: #878787;
            transition: fill 0.35s ease 0s;
        }
    }

    &.logo--white {

        .bmbk,
        .arbmbk,
        .abudhabi,
        .arabudhabi {
            path {
                fill: $white;
            }
        }

        &-full {

            .emblem,
            .bmbk,
            .arbmbk,
            .abudhabi,
            .arabudhabi {
                path {
                    fill: $white;
                }
            }
        }
    }
}

.lang-toggle {
    /*rtl:remove*/
    font-family: $arprimaryFont;
    /*rtl:raw:
    font-family: 'Apercu Pro';*/
}


.title-header {
    padding: 30px 0;

    &.no--bot-padding {
        padding-bottom: 0;
    }

    &.mod--top {
        padding-top: 50px;
    }
    
    @media #{$vpmobile} {
        padding: 20px 0;
    }
}

section {
    .title {
        margin: 0;
    }
}

.sec-padded {
    padding: 50px 0;

    &.no-pb-desktop {
        padding-bottom: 0;
    }

    @media #{$vptabletP} {
        &.no-pb-desktop {
            padding-bottom: 35px;
        }
    }

    @media #{$vpmobile} {
        padding: 30px 0;
    }
}

.sec-top-padded{
    padding-top: 30px;
    @media #{$vpmobile} {
        padding: 15px 0;
    }
}

.objectfit {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.u-mobile-only {
    @media #{$vpmobileRev} {
        display: none !important;
    }
}

.u-desktop-only {
    @media #{$vpmobile} {
        display: none !important;
    }
}

.c-grid{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    &.five-column{
        margin: 0 -15px;
        .c-column{
            flex: 0 0 20%;
            max-width: 20%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 0 15px;
        }
        @media(max-width:1199px){
            margin: 0 -10px;
            .c-column{
                flex: 0 0 25%;
                max-width: 25%;
                padding: 0 10px;
            }
        }
        @media(max-width:991px){
            .c-column{
                flex: 0 0 33%;
                max-width: 33%;
            }
        }
        @media(max-width:767px){
            .c-column{
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        @media(max-width:400px){
            .c-column{
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    &.js-mob-slider{
        @media(max-width:1199px){
            margin: 0 -15px;
            .c-column{
                flex: 0 0 20%;
                max-width: 20%;
                padding: 0 15px;
            }
        }
        @media(max-width:991px){
            margin: 0 -2px;
            .slick-list{
                overflow: visible;
            }
            .c-column{
                max-width: 100%;
                flex: 0 0 100%;
                padding:  0 2px;
            }
        }
    }
}
.b-t-0{
    border-top: 0 !important;
}

.b-l-0{
    border-left: 0 !important;
}

.b-r-0{
    border-right: 0 !important;
}

.b-b-0{
    border-bottom: 0 !important;
}

// Video
.video-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    .ic-play{
        width: 71px;
        height: 71px;
        @media #{$vptabletL} {
            width: 60px;
            height: 60px;
        }
        path{
            fill: rgba($white, 0.6);
            transition: all 0.35s ease 0s;
        }
    }
    &:hover{
        .ic-play{
            path{
                fill: rgba($white, 0.8);
            }
        }
    }
}

.videopopup-style {
    .videopopup-wrapper {
        position: relative;
        @media #{$vptabletP} {
            margin: 0 0 40px;
        }
        .video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            
            transform: translate(-50%, -50%);
            .ic-play{
                width: 71px;
                height: 71px;
                @media #{$vptabletL} {
                    width: 60px;
                    height: 60px;
                }
                path{
                    fill: rgba($white, 0.6);
                    transition: all 0.35s ease 0s;
                }
            }
            &:hover{
                .ic-play{
                    path{
                        fill: rgba($white, 0.8);
                    }
                }
            }
        }
        .video-picture{
            @media #{$vpmobile} {
                margin-left: -30px;
                margin-right: -30px;
            }
            @media #{$vpxsmobile} {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
        .video-text{
            position: absolute;
            left: 70px;
            bottom: 66px;
            max-width: 350px;
            transform: translateY(0);
            transition: all .35s ease;
            @media #{$vptabletL} {
                left: 30px;
                bottom: 30px;
            }
            @media #{$vpmobile} {
                left: 10px;
                bottom: 40px;
            }
            .txt-white {
                margin: 0;
            }
            a{
                &.btn-link{
                    color: $white;
                    text-transform: uppercase;
                    .js-tosvg{
                        path{
                            fill: $white;
                        }
                    }
                }
            }
            h5{
                color: $white;
                margin-bottom: 0;
            }
            p {
                color: $white;
            }
        }
        &::after {
            display: none;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $dateColor;
            height: 8px;
            z-index: 9;
            @media #{$vpmobile} {
                left: -30px;
                right: -30px;
            }
            @media #{$vpxsmobile} {
                left: -15px;
                right: -15px;
            }
        }
    }
    p{
        margin-top: 25px;
        &:last-child{
            margin-bottom: 20px;
        }
    }
}

.card-shadow {
    position: relative;
    overflow: visible;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-10px, -10px);
        background: $iPink;
    }
    &.shadow--purple {
        &:before {
            background: $iPurple; 
        }
    }
    &.shadow--green { 
            &:before {
            background: $iLight-green; 
        }
    }
    &.top-right {
        &:before {
            transform: translate(10px, -10px);
        }
    }
    &.bottom-right {
        &:before {
            transform: translate(10px, 10px);
        }
    }
}

.nth-even-odd {
    .card-shadow {
        &:nth-child(odd) {
            &:before {
                transform: translate(-10px, 10px);
            }
        }
    }
}

.nth-middle-item {
    .card-shadow {
        &:before {
            transform: translate(-10px, -10px);
        }

        &:nth-child(3n-1) {
            &:before {
                transform: translate(-10px, -10px);
            }
        }
    
        &:nth-child(3n+1) {
            &:before {
                transform: translate(10px, -10px);
            }
        }

        @media #{$vptabletL} {
            &:nth-child(even) {
                &:before {
                    transform: translate(-10px, -10px);
                }
            }
        
            &:nth-child(odd) {
                &:before {
                    transform: translate(-10px, 10px);
                }
            }
        }

        @media #{$vpmobile} {
            &:nth-child(odd) {
                &:before {
                    transform: translate(-10px, -10px);
                }
            }
        
            &:nth-child(even) {
                &:before {
                    transform: translate(-10px, 10px);
                }
            }
        }
    }
}

.video-picture {
    position: relative;
    padding-top: 75%;
    overflow: hidden;

    picture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.mod--pt {
    padding-top: 50px;
}

.sec-video-popup {
    .videopopup-style {
        .videopopup-wrapper {
            .video-icon {
                .ic-play {
                    @media #{$vptabletL} {
                        width: 40px;
                        height: 40px;
                    }
                    @media #{$vptabletP} {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
            .video-text{
                left: 30px;
                bottom: 40px;
                @media #{$vpdesktop} {
                    bottom: 20px;
                }
                @media #{$vptabletP} {
                    left: 30px;
                    bottom: 45px;
                }
                @media #{$vpmobile} {
                    left: 10px;
                }
                h2{
                    @media #{$vpdesktop} {
                        font-size: 34px;
                    }
                    @media #{$vptabletL} {
                        font-size: 26px;
                    }
                }
            }
        }
        h5{
            @media #{$vptabletP} {
                margin-bottom: 0;
                margin-top: 20px;
                color: $iconColor;
            }
        }
        p{
            @media #{$vptabletP} {
                margin-top: 15px;
                margin-bottom: 25px;
            }
        }
    }
}
.mod--width {
    width: 40%;

    @media #{$vptabletL} {
        width: 60%;
    }

    @media #{$vptabletP} {
        width: 100%;
    }

    &--max {
        width: 70%;
    
        @media #{$vptabletL} {
            width: 75%;
        }
    
        @media #{$vptabletP} {
            width: 100%;
        }
    }
}

.load-more{
    padding: 0 0 55px;
    text-align: center;
}
span.invalid-field{
    display: none!important;
}

img.js-tosvg {
    visibility: hidden;
}

body:not(.page-loaded):not(.is-home) {
    overflow: hidden;
    .body-wrapper {
        > *:not(.header) {
            visibility: hidden;
        }
    }
}

.bg-overlay{
    position: relative;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.3);
    }
}

.slickIndicator {
    display: block;
    font-size: 15px;
    color: $iconColor;
    padding: 10px 0;
    margin-bottom: 20px;
    position: relative;
    margin-top: 15px;
    max-width: 480px;

    .slick-dots {
        font-size: inherit;
    }

    ul {
        padding: 0;
        list-style: none;
        margin: 0;

        li {
            display: none;

            &.slick-active {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

#slick-progress,
#slick-progress-mobile {
    position: absolute;
    left: 40px;
    right: 40px;
    top: 50%;
    height: 1px;
    background-color: rgba($black, 0.2);

    span {
        position: absolute;
        height: 1px;
        width: 0px;
        background-color: $black;
        transition: all 0.25s linear;
    }
}

.sliderArrow {
    position: relative;

    .slick-arrow {
        @media #{$vptabletL} {
            width: 56px;
            height: 51px;
        }

        width: 76px;
        height: 65px;
        background-color: $linkSecondary;
        display: inline-block;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;

        .toSvg {
            width: 11px;
            height: 15px;

            polygon,
            path {
                fill: $white !important;
            }
        }

        &.slick-disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
.js-slickIndicatorMobile {
    display: none;

    @media #{$vpmobile} {
        display: block;
    }
}
.slick-arrow {
    &.slick-disabled {
        opacity: 0.5;
    }
}

.color-blue {
    color:$iBlue;
}

.color-pink {
    color:$iPink;
}

.color-black {
    color:$black;
}
.color-white {
    color:$white;
}


.direction-btn {
    &.btn-blue  {
        &:after {
            display: none;
        }
    }
}

.clip-svg {
    &--height {
        height: auto;
    }
    &--width {
        width: auto;
    }
}

.c-customBox{
    margin-bottom: 30px;
    a{
        display: block;
    }
}

.space-btn{
    margin: 0 auto;
}
.filter-popup-box {
    .select2-box {
        position: relative;
    }    
}

.sec-heading-sty{
    @media #{$vpmobile} {
        padding-bottom: 0;
        h1{
            margin-bottom: 0;
        }
    }
}
.js-msg-row {
    margin-top: 20px;
}

