
.sec-facts-counter{
    .counter-wrapper{
        padding: 80px 0;
        box-shadow: 20px 20px 50px rgba($black,0.04), 0px 0px 60px rgba($black,0.04);
        @media #{$vpmobile} {
            padding-bottom: 20px;
        }
        [class*=col-]:first-child {
            .counter-detail{
                border: 0;
            }
        }
        .counter-detail{
            position: relative;
            text-align: center;
            border-left: 1px solid $lineshade;
            padding: 0 25px;
            height: 100%;
            @media #{$vpmobile} {
                border: 0;
                padding: 0;
                margin-bottom:  60px;
                height: auto;
            }
            .image{
                min-height: 60px;
                margin-bottom: 20px;
                @media #{$vpmobile} {
                    min-height: 100%;
                    margin-bottom: 5px;
                }
                .icons{
                    width: 60px;
                    height: 60px;
                    path{
                        fill: $iSecondary;
                    }
                }
            }
            .text{
                span{
                    font-size: 50px;
                    font-weight: 400;
                    color: $iconColor;
                    i {
                        font-style: normal;
                    }
                    @media #{$vptabletL} {
                        font-size: 48px;
                    }
                    @media #{$vptabletP} {
                        font-size: 45px;
                    }
                }
                h3{
                    margin-top: 0;
                    font-size: 22px;
                    color: $iconColor;
                    text-transform: uppercase;
                    @media #{$vptabletL} {
                        font-size: 18px;
                    }
                    @media #{$vptabletP} {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}