.sec-subscribe{
    .sec-heading{
        h1{
            margin-bottom: 24px;
            @media #{$vpmobile} {
                margin-bottom: 0;
            }
        }
    }
    .subscribe-form{
        padding: 0;
        padding-right: 60px;
        @media #{$vptabletP} {
            padding-top: 20px;
            padding-right: 0px;
        }
        @media #{$vpmobile} {
            padding-top: 0;
        }

        .btn {
            padding: 12px 90px 12px 20px;
        }
    }
    .content-txt{
        @media #{$vpmobile} {
            padding-top: 30px;
        }
        p{
            margin-top: 0;
        }
    }
}