.sec-upcoming-events {
    &.bg-blue {
        .toSvg {
            path {
                fill: $white;
            }
        } 

        .btn {
            &.btn-blue {
                background-color: $iBlue;
                border-color: $iBlue;

                &:not(:disabled):not(.disabled).active,
                &:not(:disabled):not(.disabled):active,
                &:hover,
                &.focus,
                &:focus {
                    background-color: $iBlueDark;
                    border-color: $iBlueDark;
                }
            }
        }

        .b-thumb-content {
            p {
                color: $iPrimary;
                flex-grow: 1;
            }
        }
    }

    .thumb-wrapper {
        &.exhibition--card {
            .b-thumb-content-wrap {
                box-shadow: none;

                .b-thumb-content {
                    background: $white;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}