.select2-container {
    .select2-selection{
        cursor: pointer;
    }
    .select2-selection--single {
        border: 0;
        height: 48px;
        border-radius: 0;
        background-color: $lightGray;

        .select2-selection__rendered {
            color: $headingColor;
            height: 100%;
            padding-left: 20px;
            padding-right: 45px; 
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            display: block;
            line-height: 35px;
            color: #26353d;
        }
        .select2-selection__arrow {
            position: absolute;
            height: 100%;
            width: 42px;
            top: 0;
            /*rtl:remove*/
            right: 0;
            /*rtl:raw:
            left: 0 !important;*/

            b {
                width: 9px;
                height: 10px;
                border: 0;
                margin-top: -5px;
                margin-left: -11px;
                /*rtl:begin:ignore*/ 
                transform: rotate(90deg); 
                /*rtl:end:ignore*/
                transition: transform .35s linear;
                position: absolute;
                top: 50%;
                left: 50%;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 9px;
                    height: 10px;
                    margin-top: -5px;
                    margin-left: -5px;
                    visibility: visible;
                    display: inline-block;
                    background-size: 10px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/icons/ic-angle.svg);
                }
            }
        }

        .select2-selection__placeholder{
            color: $placeholder;
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            background-color: $lightGray1;
            .select2-selection__arrow {
                b {
                    /*rtl:begin:ignore*/ 
                    transform: rotate(-90deg); 
                    /*rtl:end:ignore*/
                }
            }
        }
        .select2-selection--multiple{
            background-color: $lightGray1;
            &:after{
                /*rtl:begin:ignore*/ 
                transform: rotate(-90deg); 
                /*rtl:end:ignore*/
            }
            .select2-search--inline{
                .select2-search__field{
                    background-color: $lightGray1;
                }
            }
        }
    }

    .select2-results__option[aria-selected=false],
    .select2-results__option[aria-selected] {
        transition: background-color 0.2s, color .2s;
        background-color: transparent;
        color: $headingColor;
        font-size: 14px;
        font-weight: 400;
        color: $headingColor;
        padding: 14px 20px;
        position: relative;
        transition: all .35s ease;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        &:after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .select2-results__option[aria-selected=true] {
        transition: background-color 0.2s, color .2s;
        background-color: $lightGray;
        color: $iSecondary;
    }

    &.select2-container--c-single{
        .select2-results__option[aria-selected=false],
        .select2-results__option[aria-selected] {
            padding: 14px 35px 14px 20px;
            &:after{
                right: 20px;
                width: 9px;
                height: 7px;
                background: url('../img/icons/ic-tick-green.svg') no-repeat 0 0 scroll;
                opacity: 0;
                visibility: hidden;
            }
        }
        .select2-results__option[aria-selected=true] {
            transition: background-color 0.2s, color .2s;
            background-color: $lightGray;
            color: $iSecondary;
            &:after{
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.select2-container--c-multiple{
        .select2-results__option[aria-selected=false],
        .select2-results__option[aria-selected] {
            padding: 14px 20px 14px 60px;
            &:after{
                left: 20px;
                width: 20px;
                height: 20px;
                background: url('../img/icons/round-check.png') no-repeat 0 0 scroll;
            }
        }
        .select2-results__option[aria-selected=true] {
            transition: background-color 0.2s, color .2s;
            background-color: $lightGray;
            color: $iSecondary;
            &:after{
                background-image: url('../img/icons/round-check-fill.png');
            }
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        transition: background-color 0.2s, color .2s;
        background-color: $lightGray;
    }

    .select2-selection--multiple{
        border:0;
        background-color: $lightGray;
        border-radius: 0;
        min-height: auto;
        height: 48px;
        position: relative;
        padding-right: 42px;
        .select2-selection__rendered{
            padding-left: 20px;
            padding-right: 42px;
            margin-bottom: 0;
            list-style: none;
            width: 100%;
            color: #26353d;
            >li{
                line-height: 48px;
                width: 100%;
            }
        }

        &:after{
            content: '';
            position: absolute;
            top: 50%;
            right: 23px;
            width: 9px;
            height: 10px;
            margin-top: -5px;
            display: inline-block;
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/icons/ic-angle.svg);
           /*rtl:begin:ignore*/ 
           transform: rotate(90deg); 
           /*rtl:end:ignore*/
            transition: transform .35s linear;
        }
    }
    &.select2-container--focus{
        .select2-selection--multiple{
            border:0;
        }
    }
    .select2-search--inline{
        .select2-search__field{
            margin-top: 0;
            font-size: 14px;
            color: $headingColor;
            cursor: pointer;
            width: 100%!important;
        }
    }
}

.select2-dropdown {
    border: none;
    outline: none;
    border-radius: 0;
    overflow: hidden;
    background-color: $lightGray1;
    border-top: 1px solid $white!important;
}
[dir=rtl]{
    .select2-container .select2-selection--single .select2-selection__arrow{
        right: 0;
        left: auto;
    }
}

.select2-results__options{
    overflow: auto;
    max-height: 300px;
}