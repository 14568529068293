
@mixin type($color, $font-size, $font-weight, $line-height, $margin:0, $padding:0) {
    color: $color;
    font-size: $font-size;
    line-height: $line-height;
    margin: $margin;
    padding: $padding;
    font-weight: $font-weight;
}

@mixin hidedd {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

@mixin showdd {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    max-width: 100%;
}

@mixin placeholder($a) {
    &:-moz-placeholder {
        color: $a;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $a;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $a;
        opacity: 1;
    }
    &::-webkit-input-placeholder {
        color: $a;
        opacity: 1;
    }
}
@mixin placeholderUpper {
    ::-webkit-input-placeholder {
        text-transform: uppercase;
    }
    :-moz-placeholder {
        text-transform: uppercase;
    }
    ::-moz-placeholder {
        text-transform: uppercase;
    }
    :-ms-input-placeholder {
        text-transform: uppercase;
    }
    ::placeholder {
        text-transform: uppercase;
    }
}

@keyframes stripes-move {
    0% {
        background-position: 0 0px;
    }
    100% {
        background-position: -400px 0px;
    }
}

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}