.sec-gallery-grid {
    position: relative;
    overflow: hidden;

    .c-gallery-grid {
        padding-top: 100px;

        @media #{$vptabletP} {
            padding-top: 50px;
        }
    }
}

/* entire container, keeps perspective */
.has-flipper {
    perspective: 1000px;
}

/* flip the pane when hove$iRed */
.has-flipper:hover .flipper,
.has-flipper.hover .flipper {
    transform: rotateY(180deg);
}

.has-flipper,
.front,
.back {
    width: 100%;
    height: 300px;

    @media #{$vpMXdesktopMax} {
        height: 240px;
    }
}

.back {
    &.content--box {
        background-color: $iBlue;
    }
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

/* hide back of pane during swap */
.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $iRed;
    &.white--bg {
        background-color: #FFF;
        &.content--box {
            p {
                color: #7b8a95;
            }
        }
    }
}


/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}

.c-gallery-grid {
    font-size: 0;
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        left: 75%;
        right: calc((-100vw - 1500px) / 2);
        top: 0;
        bottom: 0;
        background-color: $lightGreen;
        z-index: 0;

        @media (min-width: 1360px) {
            right: calc((-100vw - 1200px) / 2);
        }

        @media (min-width: 1200px) {
            right: calc((-100vw - 1140px) / 2);
        }

        @media (min-width: 992px) {
            right: calc((-100vw - 960px) / 2);
        }

        @media (min-width: 768px) {
            right: calc((-100vw - 720px) / 2);
        }

        @media (min-width: 576px) {
            right: calc((-100vw - 540px) / 2);
        }
    }

    .c-grid-old {
        font-size: 100%;
        float: left;
        width: 25%;
        height: 300px;

        @media #{$vpMXdesktopMax} {
            height: 240px;
        }

        position: relative;
        overflow: hidden;

        &.gird--space,
        .gird--space {
            padding: 50px;

            @media #{$vpMXdesktopMax} {
                padding: 35px;
            }

            display: flex;
            align-items: center;

            svg {
                path {
                    fill: $white;
                }
            }
            &.white--bg {
                svg {
                    path {
                        fill: #000;
                    }
                }
            }
        }

        &.event--grid {
            background-color: $white;

            .cta-btn {
                line-height: 1em;
                padding: 13px 20px;
                height: auto;
                margin-top: 10px;

                @media (max-width: 767px) {
                    padding: 11px 20px;
                    margin-top: 20px;
                }
            }

            .thumbContent {
                width: 100%;

                .b-thumb-datetime {
                    margin: 0;
                    padding: 0 0 20px;
                    max-width: 50%;

                    @media #{$vpMXdesktopMax} {
                        max-width: 60%;
                    }

                    @media #{$vptabletP} {
                        max-width: 50%;
                    }

                    @media #{$vpmobile} {
                        max-width: 75%;
                    }

                    @media #{$vpsmobile} {
                        max-width: 100%;
                    }

                    span {
                        display: block;
                        line-height: 1;
                        margin: 0;
                        padding: 0;
                        border-radius: 0px;
                    }

                    .badge-pill {
                        padding: 6px 13px 4px;
                    }
                }

                .content {
                    // margin-top: 15px;

                    h3 {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0 0 10px;
                    }

                    p {
                        margin: 0;
                        line-height: 1.4;
                    }
                }
            }

            .top-left-icon {
                left: 50px;
            }

            .eventDetails {
                display: inline-block;
                font-size: 0;
                min-width: 50px;
                white-space: nowrap;
                padding: 6px 0;
                text-transform: uppercase;
                margin-right: 10px;

                .toSvg {
                    width: 21px;
                    height: 16px;
                    display: inline-block;
                    vertical-align: middle;

                    &.eventtype {
                        width: 21px;
                        height: 20px;
                    }
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 12px;
                    margin-left: 7px;
                }

                &.event-tag {
                    span {
                        background-color: #ededed;
                        padding: 5px 10px;
                        font-weight: 400;
                        margin-left: 0;
                        line-height: 1;
                    }
                }
            }
        }

        .top-left-icon {
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 10;

            &.toSvg {
                width: 16px;
                height: 16px;
                path {
                    fill: #ffffff;
                }
            }
        }

        &.double--row-grid {
            height: 600px;
            overflow: hidden;

            @media #{$vpMXdesktopMax} {
                height: 480px;
            }
        }

        img {
            height: 101%;
            width: 101%;
            max-width: none;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }

        &.double--col-grid {
            width: 50%;

            &.gird--space {
                @media (max-width: 1199px) {
                    padding: 35px 25px;

                    .top-left-icon {
                        left: 25px;
                    }

                    .content {
                        margin-top: 7px;
                    }
                }

                @media (max-width: 991px) {
                    padding: 30px 25px;
                }

                @media (max-width: 767px) {
                    padding: 20px;
                }

                @media (max-width: 359px) {
                    padding: 20px 25px;
                }
            }
            a {
                &.c-play-icon {
                    border-bottom: none;
                    .ic-play {
                        position: absolute;
                        width: 110px;
                        height: 110px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        box-sizing: content-box;
                        transition: all .35s ease 0s;
                        opacity: 0.6; 
                        .st0 {
                            fill: #ffffff;
                        }
                    }

                }
            }
            &:hover {
                a {
                    &.c-play-icon {
                        .ic-play {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &.content--box,
        .content--box {
            p {
                color: $white;
                font-size: 12px;
                line-height: 1.6;

                a {
                    color: $white;
                    text-decoration: underline !important;
                }
            }
            &.white--bg {
                p {
                    color: #7b8a95;
                }
            }
        }

        &.has-video {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(#121212, 0.3);
                z-index: 1;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

@media #{$vptabletL} {
    .c-gallery-grid {

        .c-grid-old,
        .has-flipper,
        .front,
        .back {
            height: 220px;

            &.double--row-grid {
                height: 440px;
            }

            &.gird--space,
            .gird--space {
                padding: 50px 25px;
            }
        }
    }
}

@media #{$vptabletP} {
    .c-gallery-grid {
        .c-grid-old:nth-child(7) {
            float: right;
        }

        .c-grid-old {
            width: 50%;

            img {
                height: 100%;
                width: 100%;
                max-width: none;
                object-fit: cover;
            }

            &.m-img-contain {
                img {
                    height: auto;
                    width: 100%;
                    object-fit: initial;
                }
            }



            .dated {
                top: 26px;
                right: 25px;
            }

            .top-left-icon {
                top: 25px;
                left: 25px;

            }

        }

        .c-grid-old.double--col-grid {
            width: 100%;
        }

        .c-grid-old.double--row-grid {
            height: 360px;
        }

        .event--grid {
            height: auto;

            >svg {
                display: none;
            }
        }
    }

}

@media #{$vpmobile} {
    .c-gallery-grid {

        .c-grid-old,
        .has-flipper,
        .front,
        .back {
            height: 190px;

            &.double--row-grid {
                height: 380px;
            }
        }

        margin-left: -30px;
        margin-right: -30px;

        .c-grid-old {
            width: 50%;

            &.m-img-contain {
                img {
                    height: auto;
                    width: 100%;
                    object-fit: initial;
                }
            }


            .top-left-icon {
                top: 15px;
                left: 20px;

                &.toSvg {
                    width: 16px;
                    height: 16px;
                }
            }

            .dated {
                top: 16px;
                right: 18px;
            }

            .content--box,
            &.content--box,
            &.event--grid .thumbContent .content {
                p {
                    font-size: 13px;
                    line-height: 1.4;
                    margin-bottom: 0;
                }

                h3 {
                    font-size: 14px;
                }
            }

            &.event--grid .eventDetails span {
                font-size: 13px;
            }
        }

        .c-grid-old.double--col-grid {
            width: 100%;
        }

        .back.double--row-grid,
        .front.double--row-grid,
        .has-flipper.double--row-grid {
            height: 80vw;
        }

        .back.gird--space,
        .back .gird--space,
        .c-grid-old.gird--space,
        .c-grid-old .gird--space,
        .front.gird--space,
        .front .gird--space,
        .has-flipper.gird--space,
        .has-flipper .gird--space {
            padding: 25px 20px 20px;
        }

        .double--col-grid.gird--space {
            padding: 20px;

            .event-tag {
                padding: 0;
            }
        }

        .event--grid {
            height: auto;

            >svg {
                display: none;
            }
        }
    }
}

@media #{$vpsmmobile} {
    .c-gallery-grid {
        .c-grid-old {
            .top-left-icon {
                top: 7px;
                left: 7px;

                &.toSvg {
                    width: 12px;
                    height: 12px;
                }
            }

            .dated {
                top: 7px;
                right: 7px;
            }

            .content--box,
            &.content--box,
            &.event--grid .thumbContent .content {
                p {
                    line-height: 1.1;
                    margin-top: 7px;
                }

                h3 {
                    font-size: 12px;
                }
            }


            &.event--grid .eventDetails span {
                font-size: 12px;
            }
        }

        .back.gird--space,
        .back .gird--space,
        .c-grid-old.gird--space,
        .c-grid-old .gird--space,
        .front.gird--space,
        .front .gird--space,
        .has-flipper.gird--space,
        .has-flipper .gird--space {
            padding: 20px;
        }
    }
}

.overlay--Content {
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: 20px;
    padding-top: 100px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
    width: 100%;

    @media (max-width: 359px) {
        padding: 100px 20px 20px 25px;
    }

    .c-heading.style--5 {
        font-size: 22px;
        margin-bottom: 15px
    }

    * {
        color: $white;
    }
}

@media #{$vpxsmobile} {
    .c-grid-old {
        .flipper {
            .content--box {
                padding: 20px 10px 20px 25px;

                .top-left-icon {
                    left: 24px;
                }
            }
        }
    }
}