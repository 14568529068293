.datepicker-box {
    position: relative;

    input[type=text],
    input[type=date] {
        padding-right: 40px;
        padding-left: 60px;
        unicode-bidi: bidi-override;
    }

    .js-tosvg {
        width: 21px;
        height: 21px;
        position: absolute;
        left: 22px;
        top: 50%;
        transform: translateY(-50%);

        path {
            fill: $headingColor;
        }

        &.ic-angle {
            width: 9px;
            height: 10px;
            left: inherit;
            right: 22px;
            /*rtl:begin:ignore*/ 
            transform: rotate(90deg); 
            /*rtl:end:ignore*/
            transition: transform .35s linear;
            margin-top: -4px;
            pointer-events: none;
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                /*rtl:begin:ignore*/ 
                transform: rotate(-90deg); 
                /*rtl:end:ignore*/
            }
        }
    }

    .flatpickr-input {
        &.active {
            background-color: $lightGray1;
            ~.ic-angle {
                /*rtl:begin:ignore*/ 
                transform: rotate(-90deg); 
                /*rtl:end:ignore*/
                @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                    /*rtl:begin:ignore*/ 
                    transform: rotate(90deg); 
                    /*rtl:end:ignore*/
                }
            }
        }
    }
}

.flatpickr-calendar {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 5px 1px rgba($black, .08);
    margin-top: 10px;

    .flatpickr-current-month {
        font-size: 115%;

        .flatpickr-monthDropdown-months{
            font-size: 14px;
            color: $headingColor;
        }
    }

    .flatpickr-months {
        svg {
            vertical-align: top;
        }
    }

    .flatpickr-day {
        &.today {
            border-color: $iSecondary;
        }

        &.inRange,
        &.nextMonthDay.inRange,
        &.nextMonthDay.today.inRange,
        &.nextMonthDay:focus,
        &.nextMonthDay:hover,
        &.prevMonthDay.inRange,
        &.prevMonthDay.today.inRange,
        &.prevMonthDay:focus,
        &.prevMonthDay:hover,
        &.today.inRange,
        &:focus,
        &:hover {
            background: $lightGray;
            border-color: $lightGray;

            &:not(.selected):not(.endRange):not(.inRange):not(.startRange) {
                color: $headingColor;
            }
        }

        &.endRange,
        &.endRange.inRange,
        &.endRange.nextMonthDay,
        &.endRange.prevMonthDay,
        &.endRange:focus,
        &.endRange:hover,
        &.selected,
        &.selected.inRange,
        &.selected.nextMonthDay,
        &.selected.prevMonthDay,
        &.selected:focus,
        &.selected:hover,
        &.startRange,
        &.startRange.inRange,
        &.startRange.nextMonthDay,
        &.startRange.prevMonthDay,
        &.startRange:focus,
        &.startRange:hover {
            background: $iSecondary;
            border-color: $iSecondary;
        }
    }

    &.open{
        z-index: 80;
    }
}

.flatpickr-day.inRange {
    box-shadow: -5px 0 0 $lightGray, 5px 0 0 $lightGray;
}
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)), .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
    box-shadow: -10px 0 0 $iSecondary;
}
.dayContainer {
    padding: 15px;
}
.flatpickr-calendar:before{
    border-width: 10px;
}
.flatpickr-calendar:after{
    border-width: 9px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
    padding: 0px 10px;
}