.sec-thank-you{
    .content-txt{
        h1{
            margin-top: 0;
        }
        p{
            margin-bottom: 30px;
        }
    }
    .button-group-wrapper{
        .custom-group{
            display: flex;
            flex-wrap: wrap;
            &.invalid-field {
                .btn{
                    border-color: $linkSecondary;
                    color: $linkSecondary;
                }
            }
            .btn{
                flex-grow: 0;
                outline: none;
                font-size: 14px;
                box-shadow: none;
                font-weight: 400;
                color: $iconColor;
                margin-right: 15px;
                padding: 10px 25px;
                margin-bottom: 15px;
                border-radius: 20px;
                border: 1px solid $inputColor;
                background-color: transparent;
                transition: all 0.35s ease 0s;
                @media #{$vpmobile} {
                    font-size: 13px;
                    padding: 10px 20px;
                }
                &:not(:last-child),
                &:not(.dropdown-toggle){
                    border-radius: 0;
                }
                &:not(:disabled),
                &:not(.disabled){
                    &.active{
                        color: $white;
                        background-color: $iconColor;       
                    }
                }
                @media(min-width:1199px){
                    &:hover{
                        color: $white;
                        background-color: $iconColor;       
                    }
                }
            }
        }
        .custom-group-btn{
            @media #{$vpmobile} {
                text-align: center;
            }
            .btn{
                margin-top: 35px;
                padding: 10px 75px;
                @media #{$vpmobile} {
                    margin-top: 15px;
                }
            }
        }
    }
}