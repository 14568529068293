// EN - Secindary Font
@font-face {
    font-family: 'Apercu Pro';
    src: url('../fonts/ApercuPro-Regular.woff2') format('woff2'),
        url('../fonts/ApercuPro-Regular.woff') format('woff'),
        url('../fonts/ApercuPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('../fonts/ApercuPro-Bold.woff2') format('woff2'),
        url('../fonts/ApercuPro-Bold.woff') format('woff'),
        url('../fonts/ApercuPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('../fonts/ApercuPro-Black.woff2') format('woff2'),
        url('../fonts/ApercuPro-Black.woff') format('woff'),
        url('../fonts/ApercuPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


// AR - Primary Font
@font-face {
    font-family: '29LT Ravi';
    src: url('../fonts/29LTRavi-Bold.woff2') format('woff2'),
        url('../fonts/29LTRavi-Bold.woff') format('woff'),
        url('../fonts/29LTRavi-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '29LT Ravi';
    src: url('../fonts/29LTRavi-Black.woff2') format('woff2'),
        url('../fonts/29LTRavi-Black.woff') format('woff'),
        url('../fonts/29LTRavi-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: '29LT Ravi';
    src: url('../fonts/29LTRavi-Regular.woff2') format('woff2'),
        url('../fonts/29LTRavi-Regular.woff') format('woff'),
        url('../fonts/29LTRavi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
