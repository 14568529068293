.bannerSec {
    position: relative;
    height: 100vh;
}

.video-banenr {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;

    .vd-bgoverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: radial-gradient(ellipse at center,  rgba($black,0) 0%,rgba($black,0.6) 100%);
    }

    .video-js {
        height: 100%;
        width: 100%;
        padding: 0;
        z-index: 0;
        min-height: 450px;
        max-height: 1200px;
        object-fit: cover;
        font-family: 'object-fit: cover;';

        .vjs-tech {
            object-fit: cover;
            font-family: 'object-fit: cover;';
            height: 100%;
            width: 100%;
        }

        .vjs-control-text {
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }
}

.vd-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba($black, .5);
}

.video-js {
    button {
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    .vjs-big-play-button {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0;
        margin: auto;
        width: 110px;
        height: 110px;
        background-color: transparent;
        
        @media #{$vptabletL} {
            left: 70%;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            width:  90px;
            height: 90px;
        }

        @media #{$vpmobile} {
            width:  85px;
            height: 85px;
            left: 0;
            right: 0;
            transform: none;
        }

        @media #{$vpsmmobile} {
            width:  60px;
            height: 60px;
        }

        &:focus {
            background-color: transparent;
            box-shadow: none;
            outline: none;
        }

        .vjs-icon-placeholder {
            &:before {
                background: url('../img/icons/play-button.svg') no-repeat center center scroll;
                font-size: 0;
                opacity: .6;
                transition: all 0.35s ease 0s;
            }
        }

        &:hover{
            .vjs-icon-placeholder {
                &:before {
                    opacity: 0.8;
                }
            }
        }
    }

    .vjs-icon-play {
        &:before {
            background: url('../img/icons/play-white.svg') no-repeat center center scroll;
            font-size: 0;
            opacity: .6;
        }
    }

    &.vjs-has-started{
        &.vjs-paused{
            .vjs-big-play-button{
                display: block;
            }
            .vjs-control-bar{
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &.video-full{
        object-fit: cover;
        font-family: 'object-fit: cover;';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;

        .vjs-tech {
            object-fit: cover;
            font-family: 'object-fit: cover;';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
        }

        .vjs-poster{
            background-size: cover;
        }
    }

    &:hover {
        .vjs-big-play-button {
            background-color: transparent;
        }
    }
}

.video-btn{
    width: 110px;
    height: 110px;
    display: inline-block;
    .js-tosvg{
        width: 100%;
        height: 100%;
    }
}

.fancybox-content{
    width: 100%;
    height: 100%;
    
    .lightboxVideo-dimensions {
        width: 100%;
        height: 100%;
    }    
}

.popup-video{
    &.fancybox-content{
        background-color: transparent;
        padding: 0;
        .video-js{
            max-width: 100%;
        }
        .fancybox-button{
            svg{
                path{
                    fill: $white;
                }
            }
        }
        .vjs-poster,.vjs-big-play-button{
            display: none;
        }
    }

    &.op-0{
        opacity: 0;
    }
}

.fullscreen-video {
    padding: 45px 0;
    @media #{$vpmobile} {
        padding: 20px 0;
    }

    &.aboutus--video {
        padding: 75px 0;

        @media #{$vpsmmobile} {
            padding: 50px 0;
        }

        .video-box {
            width: 100%;
            left: 50%;
            padding-top: calc(56.25% - 200px);
            transform: translateX(-50%);
            margin: 0;

            @media #{$vptabletL} {
                width: calc(100% - 100px);
                left: 50%;
                padding-top: calc(56.25% - 100px); 
            }
            @media #{$vptabletP} {
                width: 100%;
                left: 50%;
                padding-top: 56.25%
            }
            @media #{$vpsmmobile} {
            }

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                right: calc((-100vw - 1500px) / 2);
                top: -30px;
                bottom: -30px;
                background-color: $lightGreen;
                z-index: 0;

                @media (min-width: 1360px) {
                    right: calc((-100vw - 1200px) / 2);
                }
                @media (min-width: 1200px) {
                    right: calc((-100vw - 1140px) / 2);
                }
                @media (min-width: 992px) {
                    right: calc((-100vw - 960px) / 2);
                }
                @media (min-width: 768px) {
                    right: calc((-100vw - 720px) / 2);
                }
                @media (min-width: 576px) {
                    right: calc((-100vw - 540px) / 2);
                }
            }

            &:after {
                display: none;
            }
        }
    }
    .video-text{
        &.video-text--outter {
            position: relative;
            p {
                margin-top: 25px;
                margin-bottom: 25px;
            }
            &.u-mobile-only{
                p{
                    display: none;
                }
                .btn {
                    &.btn-primary {
                        margin-top: 25px;
                    }
                }
            }
        }
    }
    .video-box {
        width: 100%;
        padding-top: 50%;
        position: relative;
        @media #{$vpmobile} {
            padding-top: 127%;
        }
        @media #{$vpsmmobile} {
            margin-right: -30px;
            margin-left: -30px;
            width: auto;
            min-width: 100%;
        }
        @media #{$vpxsmobile} {
            margin-right: -15px;
            margin-left: -15px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: #15ab95;
            height: 8px;
            z-index: 9;
        }
        .video-text{
            position: absolute;
            bottom: 80px;
            left: 100px;
            max-width: 350px;
            transform: translateY(0);
            transition: all .35s ease;
            @media #{$vptabletP} {
                bottom: 40px;
                left: 60px;
            }
            @media #{$vpmobile} {
                left: 35px;
                bottom: 45px;

                .txt-white {
                    margin: 0;
                }
            }

            p{
                color: $white;
            }

            a{
                &.btn-link{
                    color: $white;
                    text-transform: uppercase;
                    .js-tosvg{
                        path{
                            fill: $white;
                        }
                    }
                    &:hover{
                        color: $linkSecondary;
                        .js-tosvg{
                            path{
                                fill: $linkSecondary;
                            }
                        }
                    }
                }
            }
        }

        &.play-video{
            .video-text{
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transform: translateY(-20px);
            }
        }
    }
}