
.c-sec-school-2 {
    margin: 0 0 35px;
    .only--desktop {
        @media #{$vptabletP} {
            display: none;
        }
    }

    .only--mobile {
        display: none;
        &.form-steps {
            color: $lightGray1;
        }
        
        @media #{$vptabletP} {
            span {
                color: $lightGray1;
            }
            display: block;
        }
    }

    .c-heading {
        &.style--3 {
            font-size: 20px;
            font-weight: 900;
            @media #{$vpmobile} {
                font-size: 16px;
            }
        }
    }

    .o-container {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 35%;
            background: $iPink;
            height: 500px;
            max-height: 100%;
            width: 100vw;
            z-index: -1;
            box-sizing: content-box;

            @media (max-width: 1199px) {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }

            @media (max-width: 860px) {}
        }
    }

    .form-steps {
        text-align: right;
        color: $black-shade2-10;
        padding: 15px 0;
        margin: 0;
        font-size: 40px;
        font-weight: 900;
        line-height: 1;
        @media (max-width: 1199px) {
            padding: 20px 0 15px;
        }
    }

    .sec-txt {
        padding-bottom: 75px;
        margin-top: 50px;

        @media (max-width: 1199px) {
            padding-bottom: 35px;
            margin-top: 50px;
        }

        @media (max-width: 767px) {
            margin-top: 30px;
            padding-bottom: 20px;
        }

        &,
        .c-heading,
        p {
            color: $white;
        }

        
    }

    .sec-form {
        background: $white;
        padding: 35px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        top: 50px;
        position: relative;


        @media (max-width: 1199px) {
            padding: 25px 30px;
        }

        @media (max-width: 767px) {
            padding: 20px 25px;
            // margin-right: -15px;
            // margin-left: -15px;
        }

        .c-heading {
            &.style--3 {
                text-transform: uppercase;
                margin: 0 0 15px 0;
                padding: 0;
            }
        }
    }

    .action-bar {
        .btn {
            margin: 0 0 0 auto;

            .ctaicon {
                /* rtl:raw: transform: scale(-1, 1); */
            }
        }
    }
}

.c-form-2 {
    min-height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-steps-2 {
        .step {
            display: none;

            &.step--active {
                display: block;
            }
        }
    }

    .error {
        color: $iredSpan;
    }

    label {
        cursor: pointer;
    }

    .form-item {
        margin-bottom: 25px;

        &.time--box {
            .selector {
                label {
                    padding: 9px 30px;
                }
            }
        }

        .fi-label {
            display: block;
            margin-bottom: 3px;
            font-size: 18px;
            color: $black-shade2;
            margin-bottom: 20px;
            @media #{$vpmobile} {
                font-size: 15px;
            }

            span {
                color: #9e9e9e;
            }
        }

        input,
        textarea {
            &::-webkit-input-placeholder {
                color: #9e9e9e;
            }

            &:-ms-input-placeholder {
                color: #9e9e9e;
            }

            &::placeholder {
                color: #9e9e9e;
            }

            &.invalid {
                border-color: $iredSpan;
                color: $iredSpan;

                &::-webkit-input-placeholder {
                    color: $iredSpan;
                }

                &:-ms-input-placeholder {
                    color: $iredSpan;
                }

                &::placeholder {
                    color: $iredSpan;
                }
            }
        }

        textarea {
            height: 100px;
            padding: 15px 20px;
        }
    }

    .selector {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            &.selector--2inrow {
                .s-item {
                    width: 50%;
                }
            }

            &.selector--5inrow {
                .s-item {
                    width: 20%;

                    &:nth-child(5n) {
                        label {
                            border: 1px solid #ebebeb;
                        }
                    }

                    &:nth-child(n+6) {
                        label {
                            border-top: none;
                        }
                    }
                }
            }

            &.selector--4inrow {
                .s-item {
                    width: 48%;
                    margin-right: 20px;

                    &:nth-child(4n) {
                        label {
                            border: 1px solid #eeeeee;
                        }
                    }

                    &:nth-child(n+5) {
                        label {
                            border-top: none;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.selector--3inrow {
                .s-item {
                    width: 33.3333%;

                    &:nth-child(3n) {
                        label {
                            border: 1px solid #ebebeb;
                        }
                    }

                    &:nth-child(n+4) {
                        label {
                            border-top: none;
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            .s-item {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;

                &:nth-child(2n) {
                    label {
                        border-right: 1px solid #ebebeb;
                    }
                }

                &:nth-child(n+3) {
                    label {
                        border-top: none;
                    }
                }
            }
        }

        .s-item {
            position: relative;

            &:last-child {
                label {
                    border-right: 1px solid #ebebeb;
                }
            }
        }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $iPrimary;
            flex-direction: column;
            text-align: center;
            height: 100%;
            padding: 14px 30px;
            border: 1px solid #eeeeee;
            transition: color .35s;
            @media #{$vpmobile} {
                padding: 14px;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 3px solid $iBlue;
                opacity: 0;
                transition: opacity .35s;
            }

            strong {
                display: block;
                font-weight: bold;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            width: 0;
            height: 0;
            border: none;

            &:checked {
                &+label {
                    color: $iBlue;

                    &:before {
                        opacity: 1;
                    }
                }
            }

            &.invalid {
                &+label {
                    border-color: $iredSpan !important;
                    color: $iredSpan;
                }
            }
        }
    }

    .action-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 9px;
        margin-top: 10px;

        .btn {
            padding: 12px 110px 12px 20px;
        }

        @media (max-width: 420px) {
            

            .cta-btn.btn-primary {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}

.c-btn-back {
    color: #353535;
    background-size: auto 12px;
    padding: 14px 14px 14px 38px;
    position: relative;
    /* rtl:raw: padding: 15px 38px 14px 14px; */

    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 12px;
        background: url(../img/icons/chevron-left.svg) no-repeat center / contain;
        position: absolute;
        top: 50%;
        left: 10px;
        transition: transform .35s;
        transform: translateY(-50%);
        /* rtl:raw: transform: translateY(-50%) rotate(180deg); */
    }

    &:hover {
        &:after {
            background: url(../img/icons/chevron-left-blue.svg) no-repeat center / contain;
        }
    }
}
#meeting-rooms,
#other-spaces {
    margin-top: 50px; 
        .btn-right {
            text-align: right;
        }
    }
#other-spaces {
    display: none;
}
.upload-pop  {
    .upload-sec {
        .upload-btn {
            position: relative;
            display: inline-block;
            cursor: pointer;
            .file-uploader {
                max-width: 100%;
                position: absolute;
                height: 100%;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
            }
            &:hover {
                .btn-transparent {
                    color: $white;
                    background-color: $black;
                }
                .file-upload {
                    path, 
                    polygon {
                        fill: $white;
                    }
                }
            }
        }
        .upload-value {
            margin-top: 10px;
            font-size: 14px;
            color: $black;
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
.invalid-field {
    .btn-transparent {
        border-color: #e50b30;
    }
}