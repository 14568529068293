.modal{
    &.is--fullscreen{
        padding-right: 0!important;
        background-color: $white;
        .modal-dialog{
            max-width: 100%;
            margin: 0;
        }
        .modal-content{
            border-radius: 0;
            border:0;
        }
    }
    &.sty-1{
        .pop-row{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .pop-left{
                width: 310px;
                margin-right: 50px;
                flex-shrink: 0;
                @media(max-width:991px){
                    width: 250px;
                }
                @media(max-width:767px){
                    margin-right: 25px;
                    width: 150px;
                }
                @media(max-width:575px){
                    margin-right: 25px;
                    width: 100px;
                }
            }
            .pop-right{
                padding: 45px 0;
                .pop-thumb-content{
                    max-width: 600px;
                }
                @media(max-width:767px){
                    padding: 30px 0;
                }
            }
            @media(max-width:767px){
                padding-bottom: 30px;
                align-items: center;
            }
            @media(max-width:575px){
                padding-bottom: 0px;
            }
        }
        .modal-header{
            border-bottom: 0;
            background-color: $iSecondary;
            padding-top: 100px;
            position: relative;
            padding-bottom: 0;
            border-radius: 0;
            @media(max-width:575px){
                padding: 50px 0 0 0;
            }
            .close{
                position: absolute;
                right: 200px;
                top: 35px;
                padding: 6px;
                margin: 0;
                width: 36px;
                height: 36px;
                opacity: 1;
                span{
                    display: block;
                    line-height: 24px;
                    font-size: 0;
                }
                img{
                    transition: all .35s ease;
                }
                &:hover{
                    img{
                        transform: rotate(180deg);
                    }
                }
                @media(max-width:1400px){
                    right: 35px;
                }
                @media(max-width:575px){
                    right: 20px;
                    top: 20px;
                }
            }
            .pop-left{
                position: relative;
                .profile-frame{
                    position: absolute;
                    z-index: 1;
                    img{
                        box-shadow: 0 10px 10px -4px rgba($black,.2);
                    }
                    .social-icons{
                        padding:45px 25px;
                        justify-content: space-between;
                    }
                }
            }
            .pop-right{
                color: $white;
                h2{
                    margin-bottom: 12px;
                }
                h2,h5{
                    margin-top: 0;
                    color: $white;
                }
                h5{
                    text-transform: capitalize;
                    &:first-child{
                        margin-bottom: 18px;
                    }
                }
                .chips{
                    padding-top: 7px;
                    .badge-blank{
                        border-radius: 5px;
                        border-color: $white;
                        border: 1px solid $white;
                    }
                }
                .social-icons{
                    display: none;
                }
            }
            @media(max-width:767px){
                .pop-left{
                    .profile-frame{
                        position: static;
                    }
                    .social-icons{
                        display: none;
                    }
                }
                .pop-right{
                    h2{
                        font-size: 20px;
                        text-transform: capitalize;
                    }
                    h5{
                        font-size: 14px;
                        padding-right: 25px;
                    }
                    .social-icons{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 20px;
                        .js-tosvg{
                            path {
                                fill: $white;
                            }
                        }
                    }
                }
                .course-topic{
                    display: none;
                }
            }
            @media(max-width:575px){
                .pop-right{
                    h2{
                        font-size: 18px;
                    }
                    .social-icons{
                        padding-right: 50px;
                    }
                }
            }
        }
        .modal-body{
            padding-top:0;
            @media(max-width:575px){
                padding: 0;
            }
            p{
                margin-top:0;
                line-height: 27px;
                font-size: 15px;
                margin-bottom: 25px;
            }
            .course-topic{
                display: none;
            }
            .badge-blank{
                border-radius: 5px;
            }
            @media(max-width:767px){
                p{
                    font-size: 14px;
                }
                .pop-left{
                    display: none;
                }
                .course-topic{
                    display: block;
                    margin-bottom: 30px;
                    h5{
                        margin-top: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    .container{
        @media(min-width:1200px){
            max-width: 1300px;
        }
    }
}
.modal{ 
    &.sty-1{
        .popup--thumb {
            .thumb-wrapper{
                margin-left: -10px;
                margin-right: -10px;
            }
            .b-thumb-wrapper{
                padding: 10px;
            }
            .b-thumb-content{
                .thumbheading {
                    font-size: 18px;
                    text-transform: capitalize;
                    @media(max-width: 991px){
                        font-size: 16px;
                    }
                    &:hover{
                        a{
                            color: $linkSecondary;
                        }
                    }
                }
            }
        }   
        
    }   
}   
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .modal{ 
        &.sty-1{
            .pop-row {
                .pop-left {
                    width: 350px;
                }
                .pop-right{
                    width: 100%;
                }
            }
        }
    }
}