[type="checkbox"]:checked,
[type="radio"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input[type="text"],
input[type="date"],
input[type="email"],
input[type="search"] {
    font-size: 14px;
    color: $headingColor;
    background-color: $lightGray;
    line-height: 1.3em;
    font-weight: 400;
    width: 100%;
    padding: 15px 20px 15px 20px;
    border-radius: 0;
    height: 48px;
    text-align: left;
    border: 0;
}

::placeholder {
color: $placeholder;
}
:-ms-input-placeholder {
color: $placeholder;
}
::-ms-input-placeholder {
color: $placeholder;
}

select{
    width: 100%;
}

.search-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;

    input[type="text"],
    input[type="search"] {
        padding-right: 52px;
    }

    .btn {
        background-color: $lightGray;
        border-radius: 0;
        height: 48px;
        text-align: center;
        border: 0;
        position: absolute;
        right: 0;
        top: 0;
        margin-left: 0;
        line-height: 16px;
        padding: 0 14px;

        .js-tosvg {
            width: 21px;
            height: 21px;

            path {
                fill: $headingColor;
            }
        }

        &:hover {
            .js-tosvg {
                path {
                    fill: $iSecondary;
                }
            }
        }
    }
}

.btn {
    &.btn-simple {
        background-color: $lightGray;
        min-width: 108px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: $headingColor;
        border-radius: 0;
        padding: 12px 20px;

        &:hover {
            background-color: $lightGray1;
        }
    }
}

.dropdown-box {
    position: relative;

    .btn-simple {
        min-width: 152px;
        cursor: pointer;
        width: 100%;
        text-transform: none;
    }
    .dropdown {
        margin-top: 10px;
        background-color: $white;
        padding: 20px;
        box-shadow: 0 0 5px 1px rgba($black, .08);
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        min-width: 100px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transform: translateY(-20px);
        transition: all .35s ease;

        .rangeslider-box {
            width: 285px;
        }
    }

    .overlaydd {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &.show-dd {
        .dropdown {
            opacity: 1;
            visibility: visible;
            pointer-events: inherit;
            transform: translateY(0);
        }

        .overlaydd {
            opacity: 1;
            visibility: visible;
            pointer-events: inherit;
        }
    }
}

.form-group {
    &.pb-btn-none {
        margin-bottom: 25px;

        @media #{$vpmobile} {
            margin-bottom: 40px;
        }
    }
}

.filters-row {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 15px 0 0 0;
    &.disabled {
        .filters-col {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .filters-col {
        padding: 10px;
        flex: 1 0 auto;
        > * {
            width: 100%;
        }
        &.filter-col--others {
            display: none;
        }

        &.filter-col--lg {
            flex: auto;
            // max-width: 333px;
        }

        .tag-box{
            display: flex;
            align-items: center;
            justify-content: center;
            background: $lightGray;
            height: 48px;

            span {
                color: $black;
            }
        }

        .select2-box{
            .select2-container--c-single{
                width: 200px!important;
                min-width: 100%;

                @media(max-width:1570px){
                    width: 180px!important;
                }
            }
            .select2-container--c-multiple{
                width: 284px!important;
                min-width: 100%;

                @media(max-width:1570px){
                    width: 210px!important;
                }
            }
        }

        &.wd-25 {
            max-width: 375px;
        }
        @media #{$vptabletL} {
            display: none;
            flex-shrink: 1;

            &.show-mobile {
                display: block
            }
            &.filter-col--others {
                display: block;
                flex: 0 0 auto;
            }   
            .c-filter-btn {
                width: 48px;
                height: 48px;
                background-color: #f5f6f6;
                display: block;
                text-align: center;
                line-height: 45px;
            } 
        }

        @media #{$vpmobile} {
            &.show-mobile {
                flex-basis: 50%;
            }
            &.filter-col--lg {
                &.show-mobile {
                    flex-basis: auto;
                }
            }   
        }
    }

    &.sty-1{
        max-width: 100%;
        padding: 0;
        max-width: calc(100% + 20px);
        
        @media(max-width:1570px){
            max-width: inherit;
        }
        @media(max-width:1199px){
            max-width: inherit;
        }
        @media #{$vpmobile} {
            flex-wrap: nowrap;
        }
    }
}

.mobile-filter {
    display: none;
}

.flatpickr-calendar{
    padding: 20px;
    width: 355px;
    max-width: 100%;
    .flatpickr-current-month span.cur-month {
        font-weight: 400;
        font-size: 14px;
        color: #26353d;
        &::after{
            content: ",";
        }
    }
    .flatpickr-days,
    .flatpickr-rContainer {
        display: block;
        padding: 0;
        width: 100%;
    }
    .dayContainer,
    .flatpickr-weekdays .flatpickr-weekdaycontainer {
        padding: 0;
    }
    .flatpickr-months {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e4ecf0;
        .flatpickr-next-month,
        .flatpickr-prev-month {
            width: 33px;
            height: 33px;
            border-radius: 20px;
            background-color: #f5f6f6;
            svg,
            svg {
                width: 13px;
                height: 13px;
                path {
                    fill: #26353d;
                    stroke: #26353d;
                }
                /*rtl:remove*/ transform: rotate(0deg); /*rtl:raw: transform: rotate(180deg);*/
            }
        }
    }
    .flatpickr-current-month .numInputWrapper {
        width: auto;
        margin-left: 15px;
        
        input.cur-year {
            pointer-events: none;
            width: 50px;
            text-align: left;
            padding: 0 0 0 3px;
            font-size: 14px;
            font-weight: 400;
            color: #26353d;
        }
        span.arrowUp,
        span.arrowDown {
            background-color: transparent;
            border: 0;
        }
    }
    span.flatpickr-weekday {
        font-weight: bold;
        font-size: 11px;
        color: #bfc2c2;
    }
    .flatpickr-day {
        color: #3e454c;
        font-size: 12px;
        font-weight: 400;
        &.flatpickr-disabled {
            opacity: 0.2;
        }
    }
    .flatpickr-day {
        width: 33px;
        /*rtl:remove*/
        line-height: 34px;
        /*rtl:raw:
        line-height: 36px;*/
        height: 33px;
        max-width: 33%;
        flex: 0 0 14.285715%;
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        position: relative;
        border-radius: 0 !important;
        &:before,
        &:after {
            content: "";
            position: absolute;
            left:  50%;
            top: 50%;
            width: calc(100% + 1px);
            height: 100%;
            z-index: -1;
            transform: translate(-50%, -50%)
        }
        &.inRange {
            &::before {
                background-color: $iSecondary-50;
            }
        }
        &.today {
            &:not(.selected) {
                &:before {
                    border-radius: 50%;
                    width: 33px;
                    background-color: $iSecondary-50;
                }
            }
        }
        &.endRange,
        &.startRange,
        &.selected {
            color: $white;
            &::before {
                background-color: $iSecondary-50;
                width: 50%;
                transform: translate(5px ,-50%);
            }
            &::after {
                border-radius: 50%;
                width: 33px;
                background-color: $iSecondary
            }
        }
        &.endRange {
            &::before {
                background-color: $iSecondary-50;
                width: 50%;
                transform: translate(-100%, -50%);
            }
        }
    }
    .dayContainer {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        justify-content: space-between;
    }
}

.c-filter-btn {
    background-color: $lightGray;
    height: 100%;
    padding: 0 10px;
    width: 45px;
    align-items: center;
    justify-content: center;
    display: none;
    @media #{$vpmobile} {
        display: flex;
    }
    svg, img {
        width: 20px;
    }
    .txt {
        display: none;
    }
}

.mobile-filter {
    position: fixed;
    height: 100%;
    background: $white;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 1000000;
    transition: transform .35s;
    transform: translateY(-100%);
    overflow-y: auto;

    .m-filter-top-bar {
        border-bottom: 1px solid $borderLine;
        padding: 25px 30px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }
    .m-filter-action,
    .m-filter-item-list {
        padding: 0 30px;
    }
    .m-filter-item{
        margin-bottom: 25px;

        .tag-box{
            display: flex;
            align-items: center;
            justify-content: center;
            background: $lightGray;
            height: 48px;

            span {
                color: $black;
            }
        }
    }
    .rangeSlider .rangeValueRow {
        display: flex;
        margin-bottom: 40px;
        .label{
            font-size: 16px;
            color: $iconColor;
            margin-right: 10px;
        }
    }
    .m-filter-action {
        margin-bottom: 40px;
        .btn {
            text-transform: uppercase;
        }
    }
    @media(max-height:700px){
        .m-filter-top-bar{
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 30px;
        }
        .m-filter-action{
            margin-bottom: 30px;
        }
        .m-filter-item{
            margin-bottom: 30px;
        }
        .rangeSlider {
            .rangeValueRow {
                margin-bottom: 30px;
            }
        }
    }
    @media(max-height:610px){
        .m-filter-top-bar{
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 20px;
        }
        .m-filter-action{
            margin-bottom: 20px;
        }
        .m-filter-item{
            margin-bottom: 20px;
        }
        .rangeSlider {
            .rangeValueRow {
                margin-bottom: 20px;
            }
        }
    }
    @media(max-height:510px){
        .m-filter-top-bar{
            padding-top: 12px;
            padding-bottom: 12px;
            margin-bottom: 15px;
        }
        .m-filter-action{
            margin-bottom: 15px;
        }
        .m-filter-item{
            margin-bottom: 15px;
        }
        .rangeSlider {
            .rangeValueRow {
                margin-bottom: 15px;
            }
        }
    }
}

.open-filter-popup {
    .body-wrapper,
    &,
    body{
        overflow: hidden;
    }
    .mobile-filter {
        transform: translateY(0%);
    }
    .flatpickr-calendar.open {
        z-index: 9999999;
    }
}
.form-group {
    position: relative;
    margin-bottom: 45px;
    box-sizing: border-box;
    border-bottom: 1px solid $iconColor;

    @media #{$vpmobile} {
        margin-bottom: 38px;
    }

    label{
        margin: 0;
    }

    .label {
        position: absolute;
        top: 0px;
        left: -1px;
        margin: 0;
        padding: 0;
        z-index: 1;
        font-size: 14px;
        color: rgba($inputColor, 0.70);
        transition: top 0.2s, color 0.2s ease-out;
        @media #{$vpmobile} {
            font-size: 14px;
        }
    }

    .input-label{
        display: block;
        font-size: 14px;
        color: $black-shade3;
        margin-bottom: 20px;
    }

    .textarea,
    .form-control {
        position: relative;
        width: 100%;
        height: auto;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 0 0 2px;
        box-shadow: none;
        line-height: 1.8;
        background: transparent;
        color: rgba($inputColor, 0.70);
        @media #{$vpmobile} {
            font-size: 12px;
        }
        // &[aria-invalid="true"] {
        //     &+.label {
        //         top: -20px;
        //     }
        // }
        &:focus{
            outline: none;
        }
    }

    .textarea {
        z-index: 2;
        resize: none;
        height: 150px;
        padding: 15px;
        font-size: 14px;
        line-height: 1.4;
        color: rgba($inputColor, 0.70);
        background-color: rgba($inputColor, 0.03);
    }

    span {
        &.error-text {
            display: none;
            position: absolute;
            line-height: 22px;
        }
    }

    &.is--disabled {
        border-color: $linkGray;
        color: $linkGray;
    }

    &:focus,
    &.is--focus {
        outline: none;
        border-color: $iconColor;

        .label {
            top: -20px;
            outline: none;
            cursor: default;
            color: $iconColor;
            background: transparent;
        }

    }

    &.is--used {
        border-color: $white;

        .label {
            top: -14px;
            background: $iBlack;
            color: $black-shade1;
        }
    }

    &.is--error {
        border-color: $linkSecondary;

        .label {
            top: -14px;
            color: $linkSecondary;
        }

        &.is--used {

            .form-group,
            .form-control {
                color: $white;
            }
        }

        span {
            &.error-text {
                display: block;
            }
        }

        .label,
        .error-text {
            color: $linkSecondary;
        }
    }

    .select2-box {
        &.select2-box--style1{
            .select2-container {
                .select2-selection--single { 
                    height: 34px;
                    background: transparent;
                    .select2-selection__rendered {
                        font-size: 16px;
                        padding-left: 0;
                        padding-right: 10px;
                        color: rgba($inputColor, 0.70);
                        @media #{$vpmobile} {
                            padding-right: 5px;
                        }
                    }
                    .select2-selection__arrow {
                        /*rtl:remove*/ right: 1px; /*rtl:raw: left: 0; right: auto;*/
                        b{
                            width: 9px;
                            height: 13px;
                            margin-left: 0;
                            margin-top: -4px;
                            &:after{
                                width: 9px;
                                height: 13px;
                                background-size: 13px;
                                @media #{$vpmobile} {
                                    margin-top: -10px;
                                }
                            }
                        }
                    }
                    .select2-selection__placeholder{
                        font-size: 14px;
                        color: rgba($inputColor, 0.70);
                    }
                }
                .select2-results__option[aria-selected=false],
                .select2-results__option[aria-selected]{
                    padding: 14px 20px;
                    background-color: transparent;
                    color: rgba($inputColor, .70);
                }
                .select2-results__option[aria-selected=true] {
                    transition: background-color 0.2s, color .2s;
                    background-color: $lightGray;
                    color: $iSecondary;
                }
                .select2-results__option--highlighted[aria-selected]{
                    transition: background-color 0.2s, color .2s;
                    background-color: $lightGray;
                }
            }
        }
    }

    .intl-style{
        border: 0;
        width: 100%;
        font-size: 16px;
        color: $iconColor;
        padding-bottom: 7px;
    }

    .iti{ 
        &.iti--allow-dropdown{
            width: 100%;
            &.iti--separate-dial-code{
                .iti__selected-dial-code {
                    font-size: 16px;
                    margin-right: 8px;
                    color: $iconColor;
                }
                .iti__selected-flag {
                    padding-left: 0;
                    padding-bottom: 8px;
                    background-color: transparent;
                }
                .iti__flag {
                    margin-right: 10px;
                    transform: scale(1.2);
                }
                .iti__arrow{
                    width: 0;
                    height: 0;
                    padding: 4px;
                    margin-left: 6px;
                    margin-top: -1px;
                    transform: rotate(45deg);
                    border: solid $iconColor;
                    border-width: 0 1px 1px 0;
                }
                input[type=tel]{
                    /*rtl:begin:ignore*/
                    padding-left: 120px !important;
                    /*rtl:end:ignore*/
                    &::placeholder{
                        color: #7b8a95;
                    }
                }
            }
        }
    }

    .border-0{
        border-bottom: 0;
    }

    &.term-group{
        margin-bottom: 30px;
    }
    
}

.filters-col,
.m-filter-item{
    .select2-box {
        .select2-container {
            .select2-selection--single {
                .select2-selection__rendered {
                    line-height: 48px;
                }
            }
        }
    }
}

.input-wrapper{
    display: flex;
    align-items: center;
    .input-box{
        border: 0;
        margin-right: 40px;
        &:last-child{
            margin-right: 0;
        }
        
        .input-style1{
            &:checked,
            &:not(:checked){
                + label{
                    display: flex;
                    cursor: pointer;
                    position: relative;
                    font-size: 14px;
                    color: $iPrimary;
                    padding-left: 40px;
                    align-items: center;
                    transition: all 0.35s ease 0s;
                    @media #{$vpmobile} {
                        font-size: 14px;
                    }
                    &:hover {
                        color: $iBlue;
                    }
                    &:before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        border: 1px solid $iconColor;
                    }
                    &:after{
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                        background: $iBlue;
                        transition: all 0.35s ease 0s;
                        background-size: 10px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-image: url('../img/icons/ic-tick-white.svg');

                    }
                }
            }
            &:not(:checked) {
                + label {
                    &:after {
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }
            &:checked {
                + label {
                    color: $iBlue;
                    &:hover {
                        color: $iBlue;
                    }
                    &:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
        .input-style2{
            &:checked,
            &:not(:checked){
                + label{
                    &:before{
                        border-radius: 5px;
                        border: 2px solid $iconColor;
                    }
                    &:after{
                        border-radius: 5px;
                    }
                }
            }
            &:checked{
                + label{
                    .term-txt{
                        color: $iPrimary;
                        border-bottom: 1px solid $iPrimary;
                        &:hover {
                            color: $iBlue;
                            border-bottom: 1px solid $iBlue;
                        }
                    }
                }
            }
            &:not(:checked){
                + label{
                    .term-txt{
                        color: $iPrimary;
                        border-bottom: 1px solid $iPrimary;
                        &:hover {
                            color: $iSecondary;
                            border-bottom: 1px solid $iSecondary;
                        }
                    }
                }
            }
        }
        .term-txt{
            font-size: 16px;
            margin-left: 3px;
            @media #{$vpmobile} {
                font-size: 14px;
            }
        }
    }
}

.invalid-field{
    border-bottom-color: $iredSpan!important;
    
    .input-label,
    .form-control,
    .textarea,
    .label,
    .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel],
    .iti.iti--allow-dropdown.iti--separate-dial-code .iti__selected-dial-code,
    .select2-box.select2-box--style1 .select2-container .select2-selection--single .select2-selection__placeholder,
    .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel]::placeholder {
        color: $iredSpan!important;
    }
    .input-wrapper .input-box .input-style2:not(:checked)+label:before{
        border-color: #e50b30 !important;
    }
    .input-wrapper .input-box .input-style1:not(:checked)+label,.term-txt{
        color: #e50b30 !important;
        border-bottom-color: #e50b30 !important;
    }
    .input-wrapper .input-box .input-style1:not(:checked)+label:before {
        border-color: #e50b30 !important;
    }
}

.g-recaptcha{
    @media(max-width:400px){
        transform:scale(0.96);
        transform-origin:0 0;
    }
}

#reCaptchaError{
    p{
        color: $iredSpan;
        font-size: 16px;
        margin: 15px 0;
    }
}
.iti,
.iti--container {
    /*rtl:begin:ignore*/
    direction: ltr
    /*rtl:end:ignore*/
}

#failureMsg,
.failureMsg {
    color: $iredSpan;
}
#successMsg,
.successMsg {
    color: $dateColor;
}
