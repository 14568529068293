.dropdown-box{
    .palce-value{
        display: none;
    }
    &.show-dd{
        .btn{
            &.btn-simple{
                background-color: $lightGray1;
            }
        }
    }
}
.rangeSlider {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 1px;

    .irs--flat {
        padding-top: 7px;
        height: 20px;

        .irs-handle {
            width: 20px;
            height: 20px;
            top: 0;

            >i:first-child {
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background-color: $iSecondary;
                border-radius: 50%;
            }

            &.from {
                &:before {
                    content: "";
                    position: absolute;
                    left: -65vw;
                    top: 0;
                    bottom: 0;
                    background-color: rgba($white, .6);
                    width: 65vw;
                }
            }

            &.to {
                &:after {
                    content: "";
                    position: absolute;
                    right: -65vw;
                    top: 0;
                    bottom: 0;
                    background-color: rgba($white, .6);
                    width: 65vw;
                }
            }
        }

        .irs-max,
        .irs-min{
            background-color: transparent;
            font-size: 12px;
            color: $headingColor;
            font-weight: 400;
            top: -28px;
        }

        .irs-single {
            display: none;
        }

        .irs-line {
            top: 0;
            height: 5px;
            border: 0;
            border-radius: 0;
            background-color: $lightGray;
        }

        .irs-bar {
            top: 7px;
            height: 5px;
            background-color: transparent;
        }

        .irs-from,
        .irs-to {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    .rangeValueRow {
        margin-bottom: 25px;
        text-align: center;
        .rang-val {
            font-size: 16px;
            color: $iSecondary;
            display: block;
            font-weight: bold;
        }
    }
}