.world-slider {
    position: relative;
}
.cover-slider {
    position: relative;
    width: 100%;
    
    .cover-item {
        width: 100%;
        padding-top: 70%;
        position: relative;
        display: block !important;
        .cover-image {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            > picture,
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .cover-content {
            position: absolute;
            left: 50px;
            bottom: 320px;
            z-index: 2;
            @media screen and (max-width:1600px) {
                bottom: 200px;
            }
            @media #{$vpmobile} {
                bottom: 170px;
                    
            }
        }
    }
}
.cover-thumb-slider {
    position: absolute;
    z-index: 1;
    left: -34%;
    bottom: 43px;
    max-width: 750px;
    width: 100%;
    @media screen and (max-width:1600px) {
        left: -30%;
        bottom: 20px;
        max-width: 550px;
    }
    .slick-slide {
        padding: 10px;
        &.slick-current {
            .cover-image {
                &::after {
                    transition: width 0.35s ease 0;
                    width: 100%;
                }
            }
        }
    }
    .cover-item {
        display: block !important;
        padding: 10px;
        width: 100%;
        padding-top: 114%;
        position: relative;
        max-width: 190px;
        cursor: pointer;
        .cover-image {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 100%;
                left: 0;
                top: 0;
                background-color: #e21537;
                mix-blend-mode: color-burn;
                z-index: 2;
                opacity: 1;
                transition: width 0.35s ease 0s;
            }
            > picture,
            > img {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .cover-content {
            position: absolute;
            left: 20px;
            bottom: 20px;
            z-index: 2;
            color: #FFF;
            h4 {
                margin: 0;
                color: #FFF;
                font-size: 24px;
                font-weight: 400;
                @media screen and (max-width:1600px) {
                    font-size: 20px;
                }
                @media #{$vpmobile} {
                    font-size: 16px;
                }
            }
        }
    }
}

.bmbk-world-content {
    @media screen and (min-width:1600px) {
        padding-right: 110px;
    }
}

@media #{$vptabletL} {
    .world-slider {
        margin-top: 30px;
        padding-left: 100px;
    }
    .cover-thumb-slider {
        left: 0;
    }
}
@media #{$vptabletP} {
    .world-slider {
        padding-left: 0px;
    }
    .cover-thumb-slider {
        left: auto;
        right: 0;
    }
    .cover-slider .cover-item .cover-content {
        h4 {
            font-size: 26px;
        }
    }
    .cover-thumb-slider {
        max-width: 360px;
        padding-right: 60px;
        overflow: hidden;
        .slick-list,
        .slick-track {
            overflow: visible;
        }
    }
    .cover-slider .cover-item {
        padding-top: 80%
    }
}
@media #{$vpmobile} {
    .cover-thumb-slider {
        max-width: 220px;   
        padding-right: 90px;
    }
    .cover-slider .cover-item {
        padding-top: 140%
    }
    .world-slider {
        margin-left: -30px;
        margin-right: -30px
    }
}
@media #{$vpxsmobile} {
    .world-slider {
        margin-left: -15px;
        margin-right: -15px
    }
}