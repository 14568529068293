.social-icons{
    display: flex;
    .icons{
        margin-right: 30px;
        @media #{$vptabletP} {
            margin-right: 10px;
        }
        @media #{$vpmobile} {
            margin-right: 25px;
        }
        &:last-child{
            margin-right: 0;
        }
        .insvg{
            width: 20px;
            height: 20px;
            path{
                fill: $white;
                transition: fill 0.35s ease 0s;
            }
        }
        &:hover {
            .insvg{
                path{
                    fill: $linkSecondary;
                }
            }
        }
    }
    &.social--white {
        .icons{
            .insvg{
                path{
                    fill: $white;
                }
            }
            &:hover {
                .insvg{
                    path{
                        fill: $iPink;
                    }
                }
            }
        }    
    }
}

.footer{
    width: 100%;
    position: relative;
    z-index: 10;
    border-top: 1px solid $lineshade;
    background-color: #222222;
    left: 0;
    right: 0;
    bottom: 0;

    .footer-wrapper {
        padding: 30px 0 50px;
        width: 100%;
        max-width: 1810px;
        margin: 0 auto;
        @media #{$vpmobile} {
            padding: 20px 0;
        }
    }
    .f-privacy-txt{
        text-align: center;
        @media #{$vpmobile} {
            padding: 0 40px;
        }
        @media #{$vpxsmobile} {
            padding: 0px;
        }
        p{
            color: $white;
            margin-top: 0;
            @media #{$vpmobile} {
                margin-bottom: 15px;
            }
        }
        .txt{
            font-size: 14px;
            color: $white;
            text-decoration: none;
            position: relative;
            margin-right: 30px;
            display: inline-block;
            &:hover {
                color: $linkSecondary;
            }
            &:nth-child(3),
            &:last-child {
                margin-right: 0;
                &:after{
                    content: '';
                    position: absolute;
                    left: -17px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: $white;
                }
            }
            @media #{$vpmobile} {
                font-size: 12px;
            }
        }
    }
    .footer-logo{
        display: flex;
        justify-content: flex-start;
        @media #{$vpmobile} {
            justify-content: center;
        }
        &.right {
            justify-content: flex-end;
            justify-content: space-around;
            align-items: center;
        }
        
    }
    .social-icons {
        justify-content: center;
        align-items: center;
        @media #{$vpmobile} {
            margin: 30px 0;
        }
    }
    .copyright-content {
        margin-top: 20px;
        padding-top: 25px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            border-top: 1px solid #393939;
            width: 100%;
            left: 0;
            top: 0;
        }
    }
}
.home-template {
    .footer {
        margin-top: 260px;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 260px;
            max-width: 1500px;
            margin-left: calc((100% - 1500px)/2);
            margin-right: calc((100% - 1500px)/2);
            background: url(../img/texture.png) no-repeat bottom center / contain;
            top: 0;
            transform: translateY(-260px);
        }

        @media #{$vpMXdesktopMax} {
            margin-top: 205px;

            &:before {
                height: 205px;
                max-width: 1200px;
                margin-left: calc((100% - 1200px)/2);
                margin-right: calc((100% - 1200px)/2);
                transform: translateY(-205px);
            }
        }
        @media #{$vpSdesktop} {
            margin-top: 195px;

            &:before {
                height: 195px;
                max-width: 1140px;
                margin-left: calc((100% - 1140px)/2);
                margin-right: calc((100% - 1140px)/2);
                transform: translateY(-195px);
            }
        }
        @media #{$vptabletL} {
            &:before {
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }

        @media #{$vptabletP} {
            margin-top: 140px;
            &:before {
                height: 140px;
                transform: translateY(-140px);
                background-size: auto 140px;
            }
        }
        
        @media #{$vpmobile} {
            margin-top: 120px;
            &:before {
                height: 120px;
                transform: translateY(-120px);
                background-size: auto 120px;
            }
        }
    }
}