.sec-about-instructor{
    padding: 45px 0;
    @media #{$vpmobile} {
        padding: 20px 0;
        margin-bottom: 20px;
    }
    .sec-heading{
        margin-bottom: 50px;
        @media #{$vpmobile} {
            margin-bottom: 25px;
        }
    }
    .instructor-slider{
        width: 100%;
        .slick-track {
            margin: 0;
        }
        .item{
            width: 100%;
        }
    }
    .instructor-wrapper{
        display: flex;
        width: 100%;
        padding-right: 70px;
        @media #{$vpmobile} {
            padding-right: 0;
            align-items: center;
        }
        .inst-image{
            flex: 0 0 40%;
            max-width: 40%;
            margin-right: 40px;
            @media #{$vptabletL} {
                flex: 0 0 35%;
                max-width: 35%;
                margin-right: 30px;
            }
            @media #{$vpmobile} {
                flex: 0 0 30%;
                max-width: 30%;
                margin-right: 20px;
            }
        }
        .inst-content{
            flex-grow: 1;
            .txt{
                h4{
                    margin-top: 0;
                    font-weight: 500;
                }
            }
            .social-icons{
                margin: 20px 0 30px;
                @media #{$vpmobile} {
                    margin: 20px 0 0;
                    .icons{
                        margin-right: 25px;
                    }
                }
            }
        }
    }
    .inst-mobile-txt{
        margin-top: 30px;
        @media #{$vpmobile} {
            margin-top: 25px;
        }
        p{
            margin-bottom: 30px;
            @media #{$vpmobile} {
                margin-bottom: 6px;
            }
        }
    }
    .btn {
        &.btn-link{
            text-align: left;
            text-transform: uppercase;
        }
    }
        .slick-dots{
            list-style: none;
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            li{
                display: inline-block;
                vertical-align: top;
                button{
                    border:0;
                    padding: 0;
                    margin: 0;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background-color: $lightGray3;
                    margin: 0 6px;
                    font-size: 0;
                }
                &.slick-active{
                    button{
                        background-color: $black-shade1;
                    }
                }
            }
        }
}
.owl-dots{
    @media #{$vptabletP} {
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        .owl-dot{
            width: 11px;
            height: 11px;
            margin-right: 6px;
            border-radius: 50%;
            background-color: $lightGray3;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                background-color: $black-shade1;
            }
        }
    }
}