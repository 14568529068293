.instructors-wrapper{
    padding: 80px 0 50px 0;
    @media(max-width:1625px){
        padding: 60px 0 30px 0;
    }
    @media(max-width:991px){
        padding: 25px 0 0 0;
        &.slick-initialized {
            padding-right: 50px;
            display: block;
            overflow: hidden;
        }
    }
    @media #{$vpmobile} {
        padding-top: 20px;
        &.slick-initialized {
            padding-right: 90px;
            margin-left: -30px;
            margin-right: -30px;
            width: auto;
            padding-left: 30px;
        }
    }
    @media #{$vpxsmobile} {
        &.slick-initialized {
            padding-right: 50px;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
        }
    }
    &.instructors-sty1{
        padding: 38px 0 0 0;
        .c-column{
            &:last-child{
                margin-right: auto;
            }
        }
        .ins-thumb-wrapper{
            padding-bottom: 68px;
            .ins-thumb-content{
                padding-top: 15px;
                .ins-designation{
                    display: block;
                    margin-top: 0;
                }
            }
            &:hover{
                .ins-thumb-img{
                    transform: scale(1);
                    box-shadow: none;
                    .ins-thumb-effect{
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                        transform: translateY(70px);
                    }
                }
                .ins-thumb-content{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                    transform: translateY(0);
                }
            }
        }
        @media(max-width:1569px){
            padding: 30px 0 0 0;
            .ins-thumb-wrapper{
                padding-bottom: 50px;
            }
        }
        @media(max-width:575px){
            padding: 25px 0 0 0;
            .ins-thumb-wrapper{
                width: 100%;
                padding-bottom: 30px;
            }
        }
        @media(max-width:400px){
            .ins-thumb-wrapper{
                width: 100%;
            }
        }
    }
}
.ins-thumb-wrapper{
    cursor: pointer;
    flex: 0 0 100%;
    position: relative;
    .ins-thumb-img{
        position: relative;
        transition: all .35s cubic-bezier(.7,0,.3,1);
        background-color: $lightGray;
        img {
            width: 100%;
        }
        .red-overlay{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            background-color: $linkSecondary;
            mix-blend-mode: multiply;
            transition: all .35s cubic-bezier(.7,0,.3,1);
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                background-color: rgba($linkSecondary, 0.5);
            }
            @supports (-ms-ime-align:auto) {
                background-color: rgba($linkSecondary, 0.5);
            }
        }
        .ins-thumb-effect{
            position: absolute;
            left: 14px;
            bottom: 20px;
            padding-right: 50px;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transform: translateY(70px);
            transition: all .35s cubic-bezier(.7,0,.3,1);
            z-index: 1;
            .ins-name{
                font-size: 28px;
                font-weight: bold;
                color: $white;
                line-height: 30px;
                margin: 0;
                text-transform: uppercase;
                @media(max-width:1500px){
                    font-size: 22px;
                    line-height: 24px;
                }
                @media(max-width:1199px){
                    font-size: 20px;
                    line-height: 21px;
                }
            }
            .ins-designation{
                font-size: 10px;
                font-weight: 400;
                color: $white;
                margin: 8px 0 0 0;
            }
        }
    }
    .ins-thumb-content{
        padding-top: 20px;
        transform: translateY(0px);
        transition: all .35s cubic-bezier(.7,0,.3,1);
        .ins-name{
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
            color: $headingColor;
            margin: 0;
        }
        .ins-designation{
            margin: 5px 0 0 0;
            font-size: 14px;
            font-weight: 400;
            color: $iPrimary;
            display: none;
            @media(max-width:991px){
                display: block;
            }
        }
    }
    &:hover{
        @media(min-width:992px){
            .ins-thumb-img{
                z-index: 2;
                transform: scale(1.3) translateY(5%);
                box-shadow: 0 10px 10px -4px rgba($black,.2);
                .red-overlay{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }
                .ins-thumb-effect{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                    transform: translateY(0px);
                }
            }
            .ins-thumb-content{
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transform: translateY(30px);
            }
        }
        @media(min-width:1626px){
            .ins-thumb-img{
                transform: scale(1.42) translateY(4.5%);
            }
        }
    }
    &:hover,
    &.is--hover{
        z-index: 1;
    }
}
.pro-offered {
    padding-left: 20px;
    line-height: 35px;
    a {
        color: $iPrimary
    }
}