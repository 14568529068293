.audioPlayer-box{
    padding-top: 5px;
    .plyr--audio{
        
    }
}
.plyr--audio{
    .plyr__control{
        &:hover{
            background: var(--plyr-audio-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,transparent)))
        }
    }
    .plyr__controls{
        background: var(--plyr-audio-controls-background,$iSecondaryDark);
        .plyr__controls__item{
            &.plyr__control{
                width: 40px;
                height: 40px;
                border-radius: 50px;
                border: 2px solid $white;
                &:hover{
                    background: var(--plyr-audio-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,transparent)))
                }
            }
            svg{
                color: $white;
                width: 10px;
                height: 10px;
                margin: 0 auto;
                text-align: center;
            }
            &.plyr__time--current{
                &.plyr__time{
                    display: none;
                }
            }
            &.plyr__menu{
                display: none;
            }
            &.plyr__volume{
                width: auto;
                max-width: 30px;
                min-width: 30px;
                input[type=range]{
                    display: none;
                }
                .plyr__control{
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                }
            }
            &:hover{
                background: var(--plyr-audio-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,transparent)));
            }
        }
        input[type=range]{
            color: var(--plyr-range-fill-background,var(--plyr-color-main,var(--plyr-color-main,$white)));
        }
    }
}

.sec-audio {
    .img-box {
        img {
            width: 100%;
        }
    }
    .audio-content {
        &.mt-20{
            margin-top: 25px;
        }
        h1{
            margin-bottom: 15px;
        }
        p{
            margin-bottom: 20px;
            @media #{$vptabletP} {
                margin-bottom: 15px;
            }
        }
    }
}

.fancybox-toolbar{
    .fancybox-button {
        &.fancybox-button--zoom,
        &.fancybox-button--play{
            display: none;
        }
    }
}
.plyr__progress {
    margin-right: var(--plyr-range-thumb-height,25px) !important;
    margin-left: var(--plyr-range-thumb-height,16px) !important;
}