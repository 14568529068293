.home-banner {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #000;
    min-height: 450px;
    .banner-content {
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
        color: $white;
        padding-top: 115px;
        @media #{$vpmobile}{
            padding-top: 72px;
        }

        .content {
            p {
                color: $white;
                max-width: 600px;
                font-size: 16px;
                line-height: 1.5;
                @media #{$vpmobile} {
                    font-size: 14px;
                }
            }
            h1{
                span{
                    &.sprayed{
                        /*rtl:remove*/
                        padding: 13px 11px 5px 6px;
                        /*rtl:raw:
                        padding: 22px 7px 8px 6px;*/
                    }
                }
            }  
        }
    }
}
.image-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}