.hamburger {
    position: relative;
    width: 32px;
    height: 32px;
    overflow: hidden;
    &:hover {
        .bars {
            .ic-hamburger {
                .st0 {
                    fill: $iPink !important;
                }
            } 
        } 
    }


    @media #{$vptabletP} {
        width: 25px;
        height: 25px;
    }

    a {
        .bars {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            .ic-hamburger {
                .st0 {
                    fill: $black;
                    transition: all 0.35s ease;
                }
            }

            span {
                width: 32px;
                height: 5px;
                background-color: #26353d;
                position: absolute;
                top: 12px;
                left: 0;
                border-radius: 2px;
                transition: all 0.35s ease 0s;
                @media #{$vpmobile} {
                    width: 26px;
                }
                &:first-child {
                    top: auto;
                    left: auto;
                    bottom: 12px;
                    right: 0;
                }
            }
            &:hover{
                span{
                    background: $linkSecondary;
                }
                .ic-hamburger {
                    .st0 {
                        fill: $iPink !important;
                    } 
                }
            }
        }
        &.menu-open {
            .bars {
                display: none;
            }
            .menu-box {
                .header {
                    background-color: transparent;
                }
            }
        }

        .cross {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            overflow: hidden;
            transform: rotate(45deg);

            span {
                width: 32px;
                height: 5px;
                background-color: #26353d;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-200%, -50%);
                border-radius: 5px;
                transition: all 0.35s ease .0s;
                @media #{$vpmobile} {
                    width: 26px;
                }
                &:first-child {
                    width: 5px;
                    height: 32px;
                    transform: translate(-50%, 100%);
                    @media #{$vpmobile} {
                        height: 26px;
                    }
                }
            }
        }

        &.menu-open {
            .bars {
                span {
                    left: -50px;
                    transition: all 0.35s ease 0s;

                    &:first-child {
                        left: auto;
                        right: -50px;
                    }
                }
            }

            .cross {
                span {
                    transition: all 0.35s ease 0s;
                    transform: translate(-50%, -50%);

                    &:first-child {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        @media #{$vptabletLMin} {
            &:hover{
                .bars{
                    span{
                        background: $linkSecondary;
                    }
                }
            }        
            &.menu-open {
                &:hover{
                    .cross {
                        span {
                            background: $linkSecondary;
                        }
                    }
                }
            }
        }
    }
}

.logo-box {
    width: 100%;
    max-width: 120px;
    transition: all 0.3s ease 0s;
    @media #{$vptabletP} {
        max-width: 70px;
        height: 70px;
    }

    svg {
        path,
        polygon,
        rect,
        circle {
            fill: $black;
            transition: all 0.3s ease 0s;
        }
    }
    @media #{$vpmobile} {
        max-width: 60px;
    }
}

.header,
header {
    width: 100%;
    position: fixed;
    padding: 0 30px;
    z-index: 99;
    top: 0;
    background-color: rgba($white, 0);
    transition: background-color 0.35s ease .0s;

    @media #{$vptabletL} {
        padding: 0 30px;
    }

    @media #{$vpxsmobile} {
        padding: 0 15px;
    }

    .header-wrapper {
        padding: 26px 0 30px 0;
        width: 100%;
        max-width: 1810px;
        margin: 0 auto;
        border-bottom: 1px solid $lineshade;
        position: relative;
        z-index: 99;
        transition: padding 0.35s ease .0s;

        @media #{$vptabletP} {
            padding: 15px 0;
        }
    }
    .header-cta {
        @media #{$vpmobile} {
            display: none
        }
    }
    .toggle-lang {
        margin: 0 50px;
        
        @media #{$vptabletP} {
            margin: 0 30px 0 20px ;
        }


        a {
            font-size: 18px;
            color: #26353d;

            &:hover {
                color: $iPink;
            }
        }
    }

    &.header-white {
        .header-wrapper {
            border-bottom: 1px solid transparent;
        }

        .hamburger {
            a {

                .bars,
                .cross {
                    span {
                        background-color: $white;
                    }
                }
            }
        }

        .toggle-lang {
            a {
                color: $white;
            }
        }

        .brand-logo {

            .bmbk,
            .arbmbk,
            .abudhabi,
            .arabudhabi {
                path {
                    fill: $white;
                }
            }
        }
    }
}

.temp-banner-page {
    .logo-box {
        width: 100%;
        max-width: 120px;

        @media #{$vptabletP} {
            max-width: 70px;
            height: 70px;
        }
    
        svg {
            path,
            polygon,
            rect,
            circle {
                fill: $white;
            }
        }
    }
    .ic-hamburger {
        .st0 {
            fill: $white !important;
        }
    }

    &:not(.header-fixed) {

        header,
        .header {
            .header-wrapper {
                border-bottom: 1px solid transparent;
            }

            .hamburger {
                a {
                    .bars,
                    .cross {
                        span {
                            background-color: $white;
                            transition: background-color 0.35s ease 0s;
                        }
                    }
                    @media #{$vptabletLMin} {
                        &:hover {
                            .bars,
                            .cross {
                                span {
                                    background-color: $iPink;
                                }
                            }
                        }
                    }
                }
            }

            .toggle-lang {
                a {
                    color: $white;
                    transition: color 0.35s ease 0s;
                    @media #{$vptabletLMin} {
                        &:hover {
                            color: $iPink;
                        }
                    }
                }
            }

            .brand-logo {

                .bmbk,
                .arbmbk,
                .abudhabi,
                .arabudhabi {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
}
.body-wrapper {
    &:not(.temp-banner-page){
        .header,
        header {
            background-color: rgba($white, 1);
        }
    }
    &.header-fixed {
        .header,
        header {
            background-color: rgba($white, 1);
            .header-wrapper {
                padding-top: 15px; 
                padding-bottom: 15px; 
            }
            .logo-box {
                max-width: 70px;
                svg {
                    path,
                    polygon,
                    rect,
                    circle {
                        fill: #000;
                    }
                }
                @media #{$vptabletP} {
                    max-width: 60px;
                }

                @media #{$vpmobile} {
                    max-width: 50px;
                }
            }
        }
        .ic-hamburger {
            .st0 {
                fill: $black !important;
            }
        }
    }
    &.menu-open {
        .hamburger {
            a {
                .bars,
                .cross {
                    span {
                        background-color: $white;
                        transition: background 0.35s ease .0s;
                    }
                    &:hover {
                        span {
                            background-color: $iPink !important;
                        }
                    }
                }
                @media #{$vptabletLMin} {
                    &:hover{
                        .bars,
                        .cross {
                            span {
                                background-color: $linkSecondary;
                            }
                        }
                    }
                }
            }
        }

        .toggle-lang {
            a {
                color: $white;
                transition: color 0.35s ease .0s;
            }
            &:hover{
                a{
                    color: $iPink;
                }
            }
        }
        .menu-box {
            opacity: 1;
            visibility: visible;
            .social-icons {
                .icons{
                    transform: translateY(0) scale(1);
                    opacity: 1;
                    transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.4s;
                    &:nth-child(2) {
                        transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.5s;
                    }

                    &:nth-child(3) {
                        transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.6s;
                    }

                    &:nth-child(4) {
                        transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.7s;
                    }
                }
            }
            .nav,
            nav {

                ul,
                .nav-list {

                    li,
                    .nav-item {
                        left: 0;
                        opacity: 1;
                        transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0;

                        &:nth-child(1) {
                            transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.35s;
                        }

                        &:nth-child(2) {
                            transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.45s;
                        }

                        &:nth-child(3) {
                            transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.55s;
                        }

                        &:nth-child(4) {
                            transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.65s;
                        }

                        &:nth-child(5) {
                            transition: all 0.35s cubic-bezier(0.63, -0.01, 0.43, 1.4) 0.75s;
                        }

                        &:nth-child(6) {
                            transition-delay: 0.85s;
                        }
                    }
                }
            }
        }
    }
}

.menu-box {
    z-index: 98;
    position: fixed;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    overflow: hidden;
    transition: all .3s linear;
    opacity: 0;
    visibility: hidden;
   
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($iBlue, 0.97);
        // border-radius: 50%;
        z-index: -1;
        transition: all 300ms linear;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: $white;
    }
    .nav,
    nav {

        ul,
        .nav-list {
            margin: 0;
            padding: 0;
            list-style: none;
            display: block;

            li,
            .nav-item {
                display: block;
                width: 100%;
                left: -200px;
                opacity: 0;
                position: relative;
                &.nav-item-dots {
                    display: none
                }
                a,
                .nav-link {
                    font-size: 50px;
                    font-weight: 900;
                    color: #ffffff;
                    line-height: 86px;
                    transition: color 0.35s ease .0s;
                    @media #{$vptabletP} {
                        font-size: 45px; 
                        line-height: 75px;
                    }
                    .icon {
                        display: none
                    }

                    &:hover,
                    &:active
                    &:focus {
                        color: $iPink;
                    }

                    &.active {
                        color: $iPink;
                        // @media #{$vptabletP} {
                        //     color: $black;
                        //     .icon {
                        //         path {
                        //             fill: $black;
                        //         }
                        //     }
                        // }
                    }
                }
            }
        }
    }

    .scrollable-div {
        flex-basis: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        overflow: auto;
        padding: 20px 0;
    }

    .manu-alignment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px 50px 50px 160px;
        max-width: 1810px;
        margin: 0 auto;
        @media (max-width:1500px) {
            padding: 20px 40px 50px 90px;
        }
        @media #{$vptabletL} {
            padding: 40px 30px;
        }
        @media #{$vpmobile} {
            padding: 0px 30px 30px;
        }
        @media #{$vpxsmobile} {
            padding: 40px 10px;
        }
        .inner-menu {
            
        }
        .social-icons {
            flex-basis: auto;
            flex-shrink: 0;
            flex-grow: 0;
            @media #{$vpmobile} {
                padding-top: 30px;
            }
        }
    }

    .social-icons {
        position: relative;
        border-top: 1px solid rgba(255,255,255,0.2);
        padding-top: 50px;
        @media #{$vpmobile} {
            justify-content: center;
        }
        .icons {
            margin-right: 54px;
            transform: translateY(10px) scale(0);
            opacity: 0;
            &:last-child {
                margin-right: 0;
            }

            .insvg {
                path {
                    fill: $white;
                    transition: fill 0.35s ease .0s;

                }
            }
        }
    }
    @media #{$vpsmmobile} {
        .nav {
            
            ul,
            .nav-list {
                padding: 0 20px;
                li,
                .nav-item {
                    
                    a {
                        font-size: 10px;
                        padding: 7px 2px 6px;
                        display: block;
                        .icon {
                            height: 22px;
                            width:  22px;
                            margin: 0 auto 5px;
                        }
    
                    }
                }
            }
        }
    }
    @media #{$vpsmmobile} {
        .nav {
            
            ul,
            .nav-list {
                padding: 0 5px;
                li,
                .nav-item {
                    
                    a {
                        font-size: 35px;
                        padding: 10px 1px 9px;
                        display: block;
                        line-height: 45px;
                        .icon {
                            height: 20px;
                            width:  20px;
                            margin: 0 auto 3px;
                        }
    
                    }
                }
            }
        }
    }
}
.mobSubmenu {
    @media (min-width: 992px) {
        display: none !important;
    }
    display: none;
    z-index: 90;
    @media #{$vptabletP} {
        position: fixed;
        bottom: 0;
        background: rgba(0,0,0,0.9);
        width: 100%;
        left: 0;
        padding: 30px 20px 88px;
        text-align: center;
        .social-icons {
            justify-content: center;
            .icons {
                margin: 10px 20px 30px;
            }
        }
        .nav,
        nav {
            justify-content: center;
            ul,
            .nav-list {
                margin: 0;
                padding: 0;
                list-style: none;
                display: block;
                li,
                .nav-item {
                    display: block;
                    width: 100%;
                    text-align: center;
                    &.nav-item-dots {
                        display: none
                    }
                    a,
                    .nav-link {
                        color: $white;
                        font-size: 24px;
                        line-height: 86px;
                        transition: color 0.35s ease .0s;
                        .icon {
                            display: none
                        }

                        &:hover,
                        &:active,
                        &.active,
                        &:focus {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
.mobSubmenu {
    @media #{$vptabletP} {
        padding: 30px 30px 75px;
        .social-icons {
            .icons {
                margin: 10px 15px 20px;
            }
        }
        .nav,
        nav {
            justify-content: center;
            ul,
            .nav-list {
                li,
                .nav-item {
                    a,
                    .nav-link {
                        font-size: 20px;
                        line-height: 50px;
                    }
                }
            }
        }
    }
}
.inner-menu {
    display: flex;
    justify-content: flex-start;
    @media #{$vpmobile} {
        flex-direction: column;
    }
    .nav {
        flex: 0 0 40%;
        max-width: 40%;
        @media #{$vptabletP} {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media #{$vpmobile} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .contact {
        margin-top: 7px;
        .nav-item { 
            a {
                font-size: 20px !important;
                font-weight: 400 !important; 
                line-height: 50px !important;
                @media #{$vpsmmobile} {
                    line-height: 30px !important;
                }
            }
        }
    }
}
body {
    &.menu-open {
        overflow: hidden;
    }
}
.menu-open { 
        .header {
            background-color: transparent !important;
        }
        .logo-box {
            svg {
                path,
                polygon,
                rect,
                circle {
                    fill: #fff !important;
            }
        }
    }
}
