.sec-map{
    .map-content{
        h1{
            margin-top: 70px;
            margin-bottom: 30px;

            @media #{$vptabletP} {
                margin-top: 0;
            }
            @media #{$vpmobile} {
                margin-bottom: 20px;
            }
        }
        p{
            margin-bottom: 25px;
        }
        .map-detail{
            max-width: 340px;

            .map-info {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                @media #{$vpmobile} {
                    margin-bottom: 20px;
                }

                &.top {
                    align-items: flex-start;
                }

                // &:first-child{
                //     padding-top: 15px;
                // }

                .icons{
                    min-width: 25px;
                    margin-right: 15px;
                    
                    &.ic-env{
                        width: 22px;
                        height: 15px;
                    }
                    &.ic-phn{
                        width: 21px;
                        height: 17px;
                    }
                    &.ic-clock{
                        width: 22px;
                        height: 22px;
                    }
                }

                p {
                    margin: 0;
                }

                .m-contact{
                    color: $iPrimary;
                    transition: all 0.35s ease 0s;
                    @media #{$vpmobile} {
                        font-size: 13px;
                    }
                    &:hover{
                        color: $iPink;
                    }
                }
            }
        }
    }

    .map-style{
        height: 600px;
        position: relative;

        #map{
            width: 100%;
            height: 100%;
        }

        @media #{$vpmobile} {
            margin-left: -30px;
            margin-right: -30px;
        }

        @media #{$vpxsmobile} {
            margin-left: -15px;
            margin-right: -15px;
        }

        iframe{
            width: 100%;
            height: 600px;
            @media #{$vptabletP} {
                height: 450px;
            }
            @media #{$vpmobile} {
                height: 400px;
            }
        }
        
        .direction-btn{
            display: flex;
            position: absolute;
            border: 0;
            left: 20px;
            bottom: 20px;
            padding: 8px 25px 8px 8px;
            font-size: 13px;
            border-radius: 0;
            font-weight: 400;

            span {
                line-height: 1;
                padding-top: 5px;
            }

            @media #{$vpmobile} {
                border-radius: 50%;
                padding: 10px;
            }

            .icon{
                width: 21px;
                height: 21px;
                margin-right: 8px;
                @media #{$vpmobile} {
                    margin-right: 0;
                }
                path{
                    fill: $white;
                }
            }
        }
        .fulscreen-btn{
            position: absolute;
            border: 0;
            top: 10px;
            right: 10px;
            padding: 10px 12px;
            background-color: $iBlue;
            &:hover {
                background-color: $iBlueDark;
            }
            &:after {
                display: none;
            }

            @media #{$vpmobile} {
                // border-radius: 50%;
            }
            .ic-screen{
                width: 16px;
                height: 16px;
                path{
                    fill: $white;
                }
            }
        }
    }
}

.sec-form{
    &.sec-padded {
        @media #{$vpmobile} {
            padding: 0;
        }
    }
    .sec-heading{
        h1{
            margin-bottom: 30px;
            @media #{$vpmobile} {
                margin-bottom: 10px;
            }
        }
    }
    p{
        margin-top: 0;
        @media #{$vpmobile} {
            margin-bottom: 20px;
        }
    }
}

.contact-col {
    flex: 0 0 47%;
    max-width: 47%;
    padding: 0 15px;
    &.contact-offset {
        margin-left: 6%;
    }
    @media #{$vpmobile} {
        flex: 0 0 100%;
        max-width: 100%;
        &.contact-offset {
            margin-left: 0;
        }
    }
}