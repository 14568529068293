.no-result-banner {
    padding: 120px 0;
    background-color: #eaeaea;
    margin: 20px auto 30px;
    h2 {
        margin-bottom: 0;
        margin-top: 0;
    }
    h3 {
        margin-top: 5px
    }
    p {
        margin-bottom: 0;
    }
    .c-form {
        max-width: 430px;
        margin: 0 auto;
        padding-bottom: 0;
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media #{$vptabletP} {
        padding: 80px 20px;
    }
    @media #{$vpmobile} {
        padding: 50px 20px;
    }
}

.error-page-404 {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .error-title {
        font-size: 305px;
        font-weight: bold;
        line-height: 0.4;
        opacity: 0.3 !important; 
        color: $iBlue;
        margin-top: 40px
    }
    h1 {   
        display: inline-block;
        max-width: 720px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        color: $iBlue;
        &::before {
            left: 7%;
            top: -30px;
        }
        &::after {
            left: calc(7% + 9px);
            top: -21px;
        }
    }
    p {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        
    }
    .btn {
        margin-top: 25px;
    }
    @media #{$vptabletP} {
        .error-title {
            font-size: 200px;
        }
    }
    @media #{$vpmobile} {
        .error-title {
            font-size: 150px;
        }
        h1 {
            font-size: 30px;
        }
    }
}