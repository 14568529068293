.sec-category-detail{    
    @media #{$vpmobile}{
        position: relative;
    }
    
    .category-wrapper{
        display: flex;
        align-items: flex-start;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            border-bottom: 1px solid rgba(77,80,87,.2);
            width: 100%;
            bottom: -50px;
            left: 0;

            @media #{$vpmobile}{
                bottom: -35px;
            }
        }

        @media #{$vpmobile}{
            flex-direction: column-reverse;
        }

        .category-info{
            flex: 0 0 33.334%;
            max-width: 33.334%;
            padding-right: 75px;

            @media #{$vptabletL} {
                padding-right: 40px;
            }

            @media #{$vptabletL} {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media #{$vpmobile} {
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 0;
                padding-top: 30px;
            }

            .c-txt {
                &:last-child {
                    margin: 0;
                }

                .select2-box {
                    .select2-container .select2-selection--single .select2-selection__rendered {
                        line-height: 48px;
                    }

                    label {
                        color: $iPink;
                        display: block;
                        margin-top: 16px;
                    }
                }

                .number{
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        font-size: 18px;
                        padding: 8px 5px;
                        margin-right: 20px;
                        border: 1px solid $iPrimary;
                    }

                    p{
                        display: block;
                        margin: 0;
                    }
                }

                h4 {
                    text-transform: uppercase;
                }
            }
        }
        .category-content{
            flex-grow: 0;
            flex-basis: 50%;
            padding-left: 75px;
            border-left: 1px solid rgba($lineshade, 0.20);
            @media #{$vptabletL} {
                padding-left: 40px;
            }
            @media #{$vpmobile} {
                border-left: 0;
                padding-left: 0;
            }
            .c-content-txt{
                .c-txt{
                    h5{
                        span{
                            display: block;
                            font-weight: bold;
                        }
                    }
                    p{
                        margin-bottom: 20px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media #{$vpmobile} {
                        .btn.btn-primary {
                            position: absolute;
                            bottom: 0;
                            width: calc(100% - 60px);
                        }
                    }
                }
            }
        }
        .c-txt{
            margin-bottom: 30px;
            h4 {
                text-transform: uppercase;
                color: #000000;
                font-weight: 900;
            }
            h5{
                margin-top: 0;
                margin-bottom: 15px;
                span {
                    text-transform: uppercase;
                }
            }
        }
    }
} 

.time-table {
    .date-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 6px 0;
        i {
            display: block;
            height: 1px;
            flex-grow: 1;
            background-color: $line;
        }
        .date {
            padding: 0 15px 0 0;
        }
        .time {
            padding: 0 0 0 15px;
        }
    }
}
.timing-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
        span {
            flex: auto;
            flex-shrink: 0;
            margin-right: 20px;
            &:last-child {
                margin-left: 20px;
                margin-right: 0
            }
        }
        i {
            flex-basis: 100%;
            flex-shrink: 1;
            display: block;
            height: 1px;
            background: $lineshade
        }
    }

}